import { Component } from '@angular/core';
import {ApiMessageService} from "../services/api-message/api-message.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  constructor() { }

  ngOnInit() {
  }

}
