import { Component } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {ApiMessageService} from "../services/api-message/api-message.service";
import {ResetPasswordFinishService} from "./reset-password-finish.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-reset-password-finish',
  templateUrl: './reset-password-finish.component.html',
  styleUrls: ['./reset-password-finish.component.css']
})
export class ResetPasswordFinishComponent {
  token: string | null = null;

  constructor(private fb: FormBuilder,
              private apiMessageService: ApiMessageService,
              private resetPasswordFinishService: ResetPasswordFinishService,
              private activatedRoute: ActivatedRoute,
              private router: Router
  ) { }


  passwordValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.resetPasswordFinishService.validateNewPassword(control.value);
  };

  passwordMatchValidator  = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');

  if (password && confirmPassword && password.value !== confirmPassword.value) {
    return { passwordMismatch: true };
  }
  return null;
};

  resetPasswordFinishForm = this.fb.group({
    password: ['', [Validators.required], [this.passwordValidator]],
    confirmPassword: ['', [Validators.required,], []],
  }, { validator: this.passwordMatchValidator });

  get password() { return this.resetPasswordFinishForm.get('password'); }
  get confirmPassword() { return this.resetPasswordFinishForm.get('confirmPassword'); }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.token = params?.['token'];
    });
  }

  async onSubmit() {
    if (this.resetPasswordFinishForm.invalid) {
      return;
    }

    const apiMessage = await this.resetPasswordFinishService.resetPasswordFinish(
this.password?.value || '',
      this.token || '',
    );
    this.apiMessageService.setApiMessage(apiMessage);
    if (apiMessage.isSuccess) {
      this.router.navigate(['/login']);
    }
  }
}
