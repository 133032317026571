import { Component } from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormBuilder, ValidationErrors, Validators} from "@angular/forms";
import {RegisterAccountService} from "../register-account/register-account.service";
import {ApiMessageService} from "../services/api-message/api-message.service";
import {ResetPasswordStartService} from "./reset-password-start.service";

@Component({
  selector: 'app-reset-password-start',
  templateUrl: './reset-password-start.component.html',
  styleUrls: ['./reset-password-start.component.css']
})
export class ResetPasswordStartComponent {
constructor(private fb: FormBuilder,
              private resetPasswordStartService: ResetPasswordStartService,
              private apiMessageService: ApiMessageService
  ) { }

  emailValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.resetPasswordStartService.validateEmail(control.value);
  };

  resetPasswordStartForm = this.fb.group({
    email: ['', [Validators.required], [this.emailValidator]],
  });

  get email() { return this.resetPasswordStartForm.get('email'); }

  async onSubmit(){
    if (this.resetPasswordStartForm.invalid) {
      return;
    }
    const apiMessage = await this.resetPasswordStartService.resetPasswordStart(
      this.email?.value || '',
    );
    this.apiMessageService.setApiMessage(apiMessage);
    if (apiMessage.isSuccess) {
      this.resetPasswordStartForm.reset();
    }
  }
}
