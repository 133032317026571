<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop (used to position the menu correctly, for some reason)-->
<div class="md:justify-center hidden md:flex">
  <div style="width: 1440px;" class="bg-black">
  </div>
</div>

<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <form [formGroup]="resetPasswordFinishForm">
    <div class="flex justify-center">
      <div>
        <div style="max-width: 741px;">
          <div class="flex space-x-2">
            <h2 class="text-xl text-primary font-semibold border-b border-gray-600 pb-3">Update</h2>
            <h2 class="text-xl text-primary font-semibold">password</h2>
          </div>
        </div>
        <div class="form-container px-5 py-24 mt-5 space-y-6 w-80 md:w-96">
          <div>
            <img ngSrc="assets/img/home/Nominate%20Logo.png" alt="Nominate Logo" class="mx-auto w-1/2" height="78"
                 width="240"/>
          </div>

          <!-- Password Field -->
          <div>
            <div class="relative">
              <input
                type="password"
                placeholder="Password"
                class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50 text-center"
                formControlName="password"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg fill="#4A8DCB" class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
                </svg>
              </div>
            </div>
            <div class="h-1 text-center">
              <div *ngIf="password?.errors?.['required'] && (password?.touched || password?.dirty)">
                <small class="text-red-500">Password is required</small>
              </div>
              <div *ngIf="password?.errors?.['unknown'] && (password?.touched || password?.dirty)">
                <small class="text-red-500">Network error</small>
              </div>
              <div *ngIf="password?.errors?.['new_password'] && (password?.touched || password?.dirty)"
                   class="flex flex-col gap-1">
                <small *ngFor="let error of password?.errors?.['new_password']"
                       class="text-red-500 inline-block">{{error}}</small>
              </div>
            </div>
          </div>
          <!-- End Email Field -->

          <!-- Confirm Password Field -->
          <div>
            <div class="relative">
              <input
                type="password"
                placeholder="Confirm Password"
                class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50 text-center"
                formControlName="confirmPassword"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg fill="#4A8DCB" class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
                </svg>
              </div>
            </div>
            <div class="h-1 text-center">
              <div *ngIf="confirmPassword?.errors?.['required'] && (confirmPassword?.touched || confirmPassword?.dirty)">
                <small class="text-red-500">Confirm password is required</small>
              </div>
              <div *ngIf="confirmPassword?.errors?.['unknown'] && (confirmPassword?.touched || confirmPassword?.dirty)">
                <small class="text-red-500">Network error</small>
              </div>
               <div *ngIf="resetPasswordFinishForm?.errors?.['passwordMismatch'] && (confirmPassword?.touched || confirmPassword?.dirty)">
                <small class="text-red-500">Passwords don't match</small>
              </div>
              <div *ngIf="confirmPassword?.errors?.['confirm_password'] && (confirmPassword?.touched || confirmPassword?.dirty)"
                   class="flex flex-col gap-1">
                <small *ngFor="let error of confirmPassword?.errors?.['confirm_password']"
                       class="text-red-500 inline-block">{{error}}</small>
              </div>
            </div>
          </div>
          <!-- End Confirm Password Field -->

          <!-- Reset Password button -->
          <button
            (click)="onSubmit()"
            class="inline-block bg-primary text-white p-2 rounded-md text-sm px-4 active:ring focus:ring hover:text-gray-200 active:text-gray-100 w-full">
            Reset Password
          </button>

          <div class="space-y-2">
            <p style="font-size: .95rem;" class="text-center">Already have your password?
              <a routerLink="/login" class="text-primary">Sign In</a></p>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- Footer -->
  <app-footer></app-footer>
</div>
