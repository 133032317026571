import { Component } from '@angular/core';
import {LoginService} from "../services/login.service";
import {Router} from "@angular/router";
import {ApiMessageService} from "../services/api-message/api-message.service";
import {ApiService} from "../services/api.service";
import {MeModel} from "../models/me.model";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {
  isMobileMenuOpen: boolean = false;
  meModel: MeModel | null = null;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private apiMessageService: ApiMessageService,
    private apiService: ApiService
  ) {
  }

  async ngOnInit() {
    if (this.isLoggedIn) {
      this.meModel = await this.apiService.getMe();
    }
  }

  get isLoggedIn() {
    return this.loginService.isLoggedIn;
  }

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  async guestLogin() {
     const apiMessage = await this.loginService.guestLogin();

    if (apiMessage.isSuccess) {
      await this.apiService.showGreetings();
      await this.router.navigate(['/show_invites']);
      return;
    }

    this.apiMessageService.setApiMessage(apiMessage);
  }
}
