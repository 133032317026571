import { Component } from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormBuilder, ValidationErrors, Validators} from "@angular/forms";
import {QuizQuestionFormService} from "./quiz-question-form.service";
import {QuizQuestionRequest} from "./models";
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-quiz-question-form',
  templateUrl: './quiz-question-form.component.html',
  styleUrls: ['./quiz-question-form.component.css']
})
export class QuizQuestionFormComponent {

  constructor(
    private fb: FormBuilder,
    private quizQuestionFormService: QuizQuestionFormService,
    private apiService: ApiService
  ) { }

  questionTextValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.quizQuestionFormService.validateField({question_text: control.value});
  };

  questionTimerSecondsValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.quizQuestionFormService.validateField({question_timer_seconds: control.value});
  };

  endTimerMinutesValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.quizQuestionFormService.validateField({end_timer_minutes: control.value});
  };

  correctAnswerValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.quizQuestionFormService.validateField({correct_answer: control.value});
  };

  wrongAnswerValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.quizQuestionFormService.validateField({wrong_answer: control.value});
  };

  wrongAnswer2Validator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.quizQuestionFormService.validateField({wrong_answer_2: control.value});
  };

  questionForm = this.fb.group({
    question_text: ['', [Validators.required], [this.questionTextValidator]],
    question_timer_seconds: ['', [Validators.required], [this.questionTimerSecondsValidator]],
    end_timer_minutes: ['', [Validators.required], [this.endTimerMinutesValidator]],
    correct_answer: ['', [Validators.required], [this.correctAnswerValidator]],
    wrong_answer: ['', [Validators.required], [this.wrongAnswerValidator]],
    wrong_answer_2: ['', [Validators.required], [this.wrongAnswer2Validator]],
  });

  get questionText() { return this.questionForm.get('question_text'); }
  get questionTimerSeconds() { return this.questionForm.get('question_timer_seconds'); }
  get endTimerMinutes() { return this.questionForm.get('end_timer_minutes'); }
  get correctAnswer() { return this.questionForm.get('correct_answer'); }
  get wrongAnswer() { return this.questionForm.get('wrong_answer'); }
  get wrongAnswer2() { return this.questionForm.get('wrong_answer_2'); }

  async onSubmit() {
    if (this.questionForm.invalid) {
      return;
    }

    const quizQuestionRequest: QuizQuestionRequest = {
      question_text: this.questionText?.value || '',
      question_timer_seconds: parseInt(this.questionTimerSeconds?.value || '0'),
      end_timer_minutes: parseInt(this.endTimerMinutes?.value || '0'),
      correct_answer: this.correctAnswer?.value || '',
      wrong_answer: this.wrongAnswer?.value || '',
      wrong_answer_2: this.wrongAnswer2?.value || '',
    };

    await this.apiService.askQuizQuestion(quizQuestionRequest);
  }

}
