// export const API_BASE_URL = 'http://localhost:8003/api';
// export const WS_BASE_URL = 'ws://localhost:8003/ws/';
export const API_BASE_URL = 'https://nominates.co.uk/api';
export const WS_BASE_URL = 'wss://nominates.co.uk/ws/';
export const API_REGISTER_ACCOUNT_VALIDATE = `${API_BASE_URL}/register/validate/`;
export const API_REGISTER_ACCOUNT = `${API_BASE_URL}/register/`;
export const API_RESET_PASSWORD_START_VALIDATE = `${API_BASE_URL}/reset_password_start/validate/`;
export const API_RESET_PASSWORD_START = `${API_BASE_URL}/reset_password_start/`;
export const API_RESET_PASSWORD_FINISH_VALIDATE = `${API_BASE_URL}/reset_password_finish/validate/`;
export const API_RESET_PASSWORD_FINISH = `${API_BASE_URL}/reset_password_finish/`;
export const API_TOKEN = `${API_BASE_URL}/token/`;
export const API_TOKEN_REFRESH = `${API_BASE_URL}/token/refresh/`;
export const API_GUEST_TOKEN = `${API_BASE_URL}/token/guest/`;

export const API_PING = `${API_BASE_URL}/ping/`;

export const API_INVITES = `${API_BASE_URL}/invites/`;
export const API_OPEN_INVITE = `${API_BASE_URL}/invite/open/`;

export const API_ANSWER_QUESTION = `${API_BASE_URL}/invite/answer/`;

export const API_TEXT_QUESTION_VALIDATE = `${API_BASE_URL}/text_question/validate/`;

export const API_TEXT_QUESTION = `${API_BASE_URL}/text_question/`;

export const API_QUIZ_QUESTION_VALIDATE = `${API_BASE_URL}/quiz_question/validate/`;

export const API_QUIZ_QUESTION = `${API_BASE_URL}/quiz_question/`;

export const API_ONLINE_USERS = `${API_BASE_URL}/online_users/`;

export const API_NOMINATE_USER = `${API_BASE_URL}/nominate_user/`;

export const API_QUESTIONS_LIST  = `${API_BASE_URL}/questions/list/`;

export const API_QUIZ_ANSWERS  = `${API_BASE_URL}/answers/quiz/`;

export const API_TEXT_ANSWERS  = `${API_BASE_URL}/answers/text/`;

export const API_VERIFY_EMAIL = `${API_BASE_URL}/verify_email/`;

export const API_ME = `${API_BASE_URL}/me/`;
