import { Injectable } from '@angular/core';
import {ValidationErrors} from "@angular/forms";
import {API_REGISTER_ACCOUNT, API_RESET_PASSWORD_START, API_RESET_PASSWORD_START_VALIDATE} from "../services/constants";
import {ApiMessage} from "../models/api_response";
import {LoaderService} from "../services/loader/loader.service";

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordStartService {

  constructor(private loaderService: LoaderService) { }

  async resetPasswordStart(email: string): Promise<ApiMessage> {

    this.loaderService.setLoading(true);

    try {
      const body = {email};
      const response = await fetch(API_RESET_PASSWORD_START, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      this.loaderService.setLoading(false);

      if (!response.ok) {
        return {
          isSuccess: false,
          message: 'There was a server error. Try again later.'
        }
      }

      const result = await response.json();

      return {
        isSuccess: true,
        message: result.message
      }
    } catch (e) {
      console.error(e);
      this.loaderService.setLoading(false);
      return {
        isSuccess: false,
        message: 'There was a server error. Try again later.'
      }
    }
  }


   async resetPasswordStartValidate(email: string): Promise<ValidationErrors | null> {
    try {
      const body = {email};
      const response = await fetch(API_RESET_PASSWORD_START_VALIDATE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      if (response.ok) {
        return null;
      }
      return await response.json();
    } catch (e) {
      console.error(e);
      return {unknown: 'Unknown error'};
    }
  }

  async validateEmail(email: string): Promise<ValidationErrors | null> {
    const result = await this.resetPasswordStartValidate(
      email
    );
    if (result == null || result?.['email'] == null) {
      return null;
    }
    return {email: result?.['email']};
  }
}
