import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-nominate-user',
  templateUrl: './nominate-user.component.html',
  styleUrls: ['./nominate-user.component.css']
})
export class NominateUserComponent {

    private type: string | null = null;
    private questionId: string | null = null;

    constructor(private activatedRoute: ActivatedRoute,
                private apiService: ApiService,
                private router: Router) {
    }

    async nominateUser() {
      await this.router.navigate(['online_users', this.type, this.questionId]);
    }

    ngOnInit() {
      this.activatedRoute.params.subscribe(params => {
        this.type = params['questionType'];
        this.questionId = params['questionId'];
      });
    }

  async nominateRandomUser() {
      await this.apiService.nominateRandomUser(
        this.type as string,
        parseInt(this.questionId as string)
      )
  }
}
