<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop (used to position the menu correctly, for some reason)-->
<div class="md:justify-center hidden md:flex">
  <div style="width: 1440px;" class="bg-black">
  </div>
</div>

<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <div class="flex justify-center">
    <div>
      <div style="max-width: 741px;">
        <div class="flex space-x-2">
          <h2 class="text-xl text-primary font-semibold border-b border-gray-600 pb-3">Select</h2>
          <h2 class="text-xl text-primary font-semibold">a question to view answers</h2>
        </div>
      </div>

      <div class="flex gap-4 mt-4">
        <a (click)="setQuestionType('quiz')"
          [className]="isQuizType ? 'pb-1 inline-block border-b-4 border-gray-400 cursor-pointer' :
          'pb-1 inline-block border-b-4 hover:border-gray-400 border-white cursor-pointer'">Quiz Questions</a>

        <a (click)="setQuestionType('text')"
          [className]="isTextType ? 'pb-1 inline-block border-b-4 border-gray-400 cursor-pointer' :
          'pb-1 inline-block border-b-4 hover:border-gray-400 border-white cursor-pointer'">Text Questions</a>
      </div>

      <!-- Mobile table -->
      <div class="form-container mt-5 space-y-6 md:hidden" style="width: 320px;">
        <table class="border-2 w-full border-gray-200">
          <tbody>
          <tr class="bg-primary text-white text-center">
            <th class="py-2 border-r-2 border-gray-300">Question</th>
            <th class="py-2 border-r-2 border-gray-300">Author</th>
            <th class="py-2">Answers</th>
          </tr>

          <tr class="text-primary text-center" *ngIf="questions.length == 0">
            <td class="p-3 text-center" colspan="3">No questions found</td>
          </tr>
           <ng-container *ngFor="let question of questions; let i = index">
            <tr [class]=" i % 2 == 0 ? 'text-center' : 'text-center bg-gray-200'">
              <td class="p-3 border-r-2 border-gray-300">{{question.question_text}}</td>
              <td class="p-3 border-r-2 border-gray-300">{{question.question_author}}</td>
              <td class="p-3">
                <a *ngIf="isQuizType" [routerLink]="'/quiz_question_answers/' + question.id" class="cursor-pointer text-primary">Answers</a>
                <a *ngIf="isTextType" [routerLink]="'/text_question_answers/' + question.id" class="cursor-pointer text-primary">Answers</a>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>

      <div class="form-container mt-5 space-y-6 hidden md:block" style="width: 740px;">
        <table class="border-2 w-full border-gray-200 table-fixed">
          <tbody>
          <tr class="bg-primary text-white text-center">
            <th class="py-2 border-r-2 border-gray-300">Question</th>
            <th class="py-2 border-r-2 border-gray-300">Author</th>
            <th class="py-2 border-r-2 border-gray-300">Created At</th>
            <th class="py-2">Answers</th>
          </tr>

          <tr class="text-primary text-center" *ngIf="questions.length == 0">
            <td class="p-3 text-center" colspan="4">No questions found</td>
          </tr>

          <ng-container *ngFor="let question of questions; let i = index">
            <tr [class]=" i % 2 == 0 ? 'text-center' : 'text-center bg-gray-200'">
              <td class="p-3 border-r-2 border-gray-300">{{question.question_text}}</td>
              <td class="p-3 border-r-2 border-gray-300">{{question.question_author}}</td>
              <td class="p-3 border-r-2 border-gray-300">{{question.created_at}}</td>
              <td class="p-3">
                <a *ngIf="isQuizType" [routerLink]="'/quiz_question_answers/' + question.id" class="cursor-pointer text-primary">Answers</a>
                <a *ngIf="isTextType" [routerLink]="'/text_question_answers/' + question.id" class="cursor-pointer text-primary">Answers</a>
              </td>
          </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <app-footer></app-footer>
</div>
