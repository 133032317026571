import { Component } from '@angular/core';
import {ApiService} from "../services/api.service";
import {Question} from "../models/question-model";

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent {
    currentQuestionType = 'quiz';
    questions: Question[] = [];

    get isQuizType() {
      return this.currentQuestionType === 'quiz';
    }

    get isTextType() {
      return this.currentQuestionType === 'text';
    }

    loadQuestions() {
      this.apiService.getQuestions(this.currentQuestionType).then((questions: Question[]) => {
        this.questions = questions;
      });
    }

    constructor(private apiService: ApiService) {
      this.loadQuestions();
    }

    setQuestionType(questionType: string) {
      this.currentQuestionType = questionType;
      this.loadQuestions();
    }
}
