import {Router} from '@angular/router';
import {ApiMessageService} from "./api-message/api-message.service";
import {inject} from "@angular/core";

export function authGuard() {
    const apiMessageService = inject(ApiMessageService);
    const router = inject(Router);

    const access = localStorage.getItem('access');
    const refresh = localStorage.getItem('refresh');
    const isAuthenticated = !!access && !!refresh;

    if (isAuthenticated) {
      return true; // Allow access to the route
    } else {
      apiMessageService.setApiMessage({
        message: 'You must be logged in to access that page.',
        isSuccess: false
      });
      router.navigate(['/login']); // Redirect to the login page
      return false; // Deny access to the route
    }
}

