import {Injectable} from '@angular/core';
import {ValidationErrors} from "@angular/forms";
import {API_REGISTER_ACCOUNT, API_REGISTER_ACCOUNT_VALIDATE} from "../services/constants";
import {LoaderService} from "../services/loader/loader.service";
import {ApiMessage} from "../models/api_response";

@Injectable({
  providedIn: 'root'
})
export class RegisterAccountService {

  constructor(private loaderService: LoaderService) {
  }

  async validateUsername(username: string): Promise<ValidationErrors | null> {
    const result = await this.registerAccountValidate(
      username,
      '',
      ''
    );
    if (result == null || result?.['username'] == null) {
      return null;
    }
    return {username: result?.['username']};
  }

  async validateEmail(email: string): Promise<ValidationErrors | null> {
    const result = await this.registerAccountValidate(
      '',
      email,
      ''
    );
    if (result == null || result?.['email'] == null) {
      return null;
    }
    return {email: result?.['email']};
  }

  async validatePassword(password: string): Promise<ValidationErrors | null> {
    const result = await this.registerAccountValidate(
      '',
      '',
      password
    );
    if (result == null || result?.['password'] == null) {
      return null;
    }
    return {password: result?.['password']};
  }

  async registerAccountValidate(username: string, email: string, password: string): Promise<ValidationErrors | null> {
    try {
      const body = {username, email, password};
      const response = await fetch(API_REGISTER_ACCOUNT_VALIDATE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      if (response.ok) {
        return null;
      }
      return await response.json();
    } catch (e) {
      console.error(e);
      return {unknown: 'Unknown error'};
    }
  }

  async registerAccount(username: string, email: string, password: string): Promise<ApiMessage> {
    this.loaderService.setLoading(true);

    try {
      const body = {username, email, password};
      const response = await fetch(API_REGISTER_ACCOUNT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      this.loaderService.setLoading(false);

      if (!response.ok) {
        return {
          isSuccess: false,
          message: 'There was a server error. Try again later.'
        }
      }

      const result = await response.json();

      return {
        isSuccess: true,
        message: result.message
      }
    } catch (e) {
      console.error(e);
      this.loaderService.setLoading(false);
      return {
        isSuccess: false,
        message: 'There was a server error. Try again later.'
      }
    }
  }
}
