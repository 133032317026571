<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop -->
<div class="hidden md:block">
  <div style="position: relative;text-align: center;">
    <img style="width: 100%;height: 517px;" ngSrc="assets/img/about/HeroImage.png" height="504" alt="Hero Image"
         width="1440"/>
    <div style="position: absolute;top: 90%;
          left: 50%;transform: translate(-50%, -90%);
          font-size: 24px;text-align: center;
          word-spacing: 0;
          color: black;
          " class="font-bold bg-white">
      Billboard ad for Nominate on a motorway using Out-of-Home advertising
    </div>
  </div>
</div>

<!-- Hero Mobile Desktop -->
<div class="md:hidden" style="position: relative;text-align: center;">
  <img ngSrc="assets/img/about/HeroImage.png" alt="Hero Image" height="504" width="1440"/>
  <div style="position: absolute;top: 90%;
            left: 50%;transform: translate(-50%, -90%);
            font-size: 17px;line-height: 30px;text-align: center;
            word-spacing: 0;
            color: black;
               " class="font-bold bg-white">
    Billboard ad for Nominate on a motorway using Out-of-Home advertising
  </div>
</div>


<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <!-- About Nominate-->
  <div class="flex space-x-2">
    <h2 class="text-2xl text-primary font-semibold border-b border-gray-600 pb-1">About</h2>
    <h2 class="text-2xl text-primary font-semibold">Nominate</h2>
  </div>
  <p class="mt-4 text-gray-600">
    Nominate connects people through interactive quizzes that inspire curiosity, foster creativity, and encourage
    friendly competition.
    With our innovative technology, we're changing the way people learn and interact with each other.
    Our brand is best described as interactive, engaging, and educational.
    We focus on community building, offering a personalized and fun quiz experience. Our mission is to make quizzes a
    meaningful and impactful part of everyday life, promoting lifelong learning for all.
    Join us in revolutionizing the way you think about quizzes.
  </p>

  <!-- How it works in 6 steps !-->
  <div class="flex space-x-2 mt-4">
    <h2 class="text-2xl text-primary font-semibold border-b border-gray-600 pb-1">How</h2>
    <h2 class="text-2xl text-primary font-semibold">it works in 6 simple steps</h2>
  </div>

  <div class="hidden md:block">
    <div class="mt-4 flex justify-between">

      <div class="w-56 lg:w-72 text-center gap-2 p-4">
        <div class="flex justify-center">
          <div class="p-1 border border-gray-400 rounded" style="background-color: #FFFABD">
            <img ngSrc="assets/img/about/choose%20category.png" alt="choose category" height="122" width="124"/>
          </div>
        </div>
        <h3 class="text-xl border-b-2 border-gray-900 inline-block pb-1 items-center my-3 font-bold">1. Choose
          category</h3>
        <p class="text-gray-600">
          The host can choose between two options: text-based or quiz-based
        </p>
      </div>

      <div class="w-56 lg:w-72 text-center gap-2 p-4">
        <div class="flex justify-center">
          <div class="p-1 border border-gray-400 rounded" style="background-color: #FFFABD">
            <img ngSrc="assets/img/about/quiz%20category.png" alt="quiz category" height="122" width="124"/>
          </div>
        </div>
        <h3 class="text-xl border-b-2 border-gray-900 inline-block pb-1 items-center mb-3 mt-7 font-bold">2. Quiz
          category</h3>
        <p class="text-gray-600">
          The host chooses the "quiz" option and proceeds to the next step
        </p>
      </div>

      <div class="w-56 lg:w-72 text-center gap-2 p-4">
        <div class="flex justify-center">
          <div class="py-1 px-3 border border-gray-400 rounded" style="background-color: #FFFABD">
            <img ngSrc="assets/img/about/Form.png" alt="choose category" height="118" width="88"/>
          </div>
        </div>
        <h3 class="text-xl border-b-2 border-gray-900 inline-block pb-1 items-center my-3 font-bold mt-5">3. Form
          Requirement</h3>
        <p class="text-gray-600">
          The host proceeds to fill out the quiz form, including the question,
          the correct answer, wrong answers, and timers for both the question and game duration
        </p>
      </div>

    </div>

    <div class="mt-4 flex justify-between">

      <div class="w-56 lg:w-72 text-center gap-2 p-4">
        <div class="flex justify-center">
          <div class="p-1 border border-gray-400 rounded" style="background-color: #FFFABD">
            <img ngSrc="assets/img/about/Nominate%20a%20user.png" alt="choose category" height="89" width="96"/>
          </div>
        </div>
        <h3 class="text-xl border-b-2 border-gray-900 inline-block pb-1 items-center my-3 font-bold">4. Nominate a
          user</h3>
        <p class="text-gray-600">
          After filling in the quiz form, the host will proceed to nominate an online user or select a random user to
          answer the question
        </p>
      </div>

      <div class="w-56 lg:w-72 text-center gap-2 p-4">
        <div class="flex justify-center">
          <div class="py-1 px-4 border border-gray-400 rounded" style="background-color: #FFFABD">
            <img ngSrc="assets/img/about/User%20answers.png" alt="choose category" height="88" width="82"/>
          </div>
        </div>
        <h3 class="text-xl border-b-2 border-gray-900 inline-block pb-1 items-center my-3 font-bold">5. User
          answers</h3>
        <p class="text-gray-600">
          The user who receives the question must answer it within a designated time frame, and then they nominate
          another user to continue the game
        </p>
      </div>

      <div class="w-56 lg:w-72 text-center gap-2 p-4">
        <div class="flex justify-center">
          <div class="pl-1 pr-3 pt-3 border border-gray-400 rounded" style="background-color: #FFFABD">
            <img ngSrc="assets/img/about/Answers.png" alt="choose category" height="89" width="89"/>
          </div>
        </div>
        <h3 class="text-xl border-b-2 border-gray-900 inline-block pb-1 items-center my-3 font-bold">6. Answers</h3>
        <p class="text-gray-600">
          After the game timer runs out, all participants can view their responses along with the correct and incorrect
          answers on a leaderboard
        </p>
      </div>
    </div>
  </div>

  <!-- Where Nominate is used Mobile-->
  <div class="md:hidden mt-4">
    <div class="flex flex-col items-center gap-4">

      <div class="w-full text-center gap-2 p-4">
        <div class="flex justify-center">
          <div class="p-1 border border-gray-400 rounded" style="background-color: #FFFABD">
            <img ngSrc="assets/img/about/choose%20category.png" alt="choose category" height="122" width="124"/>
          </div>
        </div>
        <h3 class="text-xl border-b-2 border-gray-900 inline-block pb-1 items-center my-3 font-bold">1. Choose
          category</h3>
        <p class="text-gray-600">
          The host can choose between two options: text-based or quiz-based
        </p>
      </div>

      <div class="w-full text-center gap-2 p-4">
        <div class="flex justify-center">
          <div class="p-1 border border-gray-400 rounded" style="background-color: #FFFABD">
            <img ngSrc="assets/img/about/quiz%20category.png" alt="quiz category" height="101" width="119"/>
          </div>
        </div>
        <h3 class="text-xl border-b-2 border-gray-900 inline-block pb-1 items-center mb-3 mt-7 font-bold">2. Quiz
          category</h3>
        <p class="text-gray-600">
          The host chooses the "quiz" option and proceeds to the next step
        </p>
      </div>

      <div class="w-full text-center gap-2 p-4">
        <div class="flex justify-center">
          <div class="py-1 px-3 border border-gray-400 rounded" style="background-color: #FFFABD">
            <img ngSrc="assets/img/about/Form.png" alt="choose category" height="118" width="88"/>
          </div>
        </div>
        <h3 class="text-xl border-b-2 border-gray-900 inline-block pb-1 items-center my-3 font-bold mt-5">3. Form
          Requirement</h3>
        <p class="text-gray-600">
          The host proceeds to fill out the quiz form, including the question,
          the correct answer, wrong answers, and timers for both the question and game duration
        </p>
      </div>

      <div class="w-full text-center gap-2 p-4">
        <div class="flex justify-center">
          <div class="p-1 border border-gray-400 rounded" style="background-color: #FFFABD">
            <img ngSrc="assets/img/about/Nominate%20a%20user.png" alt="choose category" height="89" width="96"/>
          </div>
        </div>
        <h3 class="text-xl border-b-2 border-gray-900 inline-block pb-1 items-center my-3 font-bold">4. Nominate a
          user</h3>
        <p class="text-gray-600">
          After filling in the quiz form, the host will proceed to nominate an online user or select a random user to
          answer the question
        </p>
      </div>

      <div class="w-full text-center gap-2 p-4">
        <div class="flex justify-center">
          <div class="py-1 px-4 border border-gray-400 rounded" style="background-color: #FFFABD">
            <img ngSrc="assets/img/about/User%20answers.png" alt="choose category" height="88" width="82"/>
          </div>
        </div>
        <h3 class="text-xl border-b-2 border-gray-900 inline-block pb-1 items-center my-3 font-bold">5. User
          answers</h3>
        <p class="text-gray-600">
          The user who receives the question must answer it within a designated time frame, and then they nominate
          another user to continue the game
        </p>
      </div>

      <div class="w-full text-center gap-2 p-4">
        <div class="flex justify-center">
          <div class="pl-1 pr-3 pt-3 border border-gray-400 rounded" style="background-color: #FFFABD">
            <img ngSrc="assets/img/about/Answers.png" alt="choose category" height="89" width="89"/>
          </div>
        </div>
        <h3 class="text-xl border-b-2 border-gray-900 inline-block pb-1 items-center my-3 font-bold">6. Answers</h3>
        <p class="text-gray-600">
          After the game timer runs out, all participants can view their responses along with the correct and incorrect
          answers on a leaderboard
        </p>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <app-footer></app-footer>
</div>
