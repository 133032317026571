import { Component } from '@angular/core';
import {LoginService} from "../services/login.service";
import {ApiService} from "../services/api.service";
import {ApiMessageService} from "../services/api-message/api-message.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  constructor(
    private loginService: LoginService,
    private apiService: ApiService,
    private router: Router,
    private apiMessageService: ApiMessageService
  ) {
  }

  async guestLogin() {
     const apiMessage = await this.loginService.guestLogin();

    if (apiMessage.isSuccess) {
      await this.apiService.showGreetings();
      await this.router.navigate(['/show_invites']);
      return;
    }

    this.apiMessageService.setApiMessage(apiMessage);
  }
}
