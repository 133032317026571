<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop (used to position the menu correctly, for some reason)-->
<div class="md:justify-center hidden md:flex">
  <div style="width: 1440px;" class="bg-black">
  </div>
</div>

<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <div class="flex justify-center">
    <div>
      <div style="max-width: 741px;">
        <div class="flex space-x-2">
          <h2 class="text-xl text-primary font-semibold border-b border-gray-600 pb-3">Answers</h2>
          <h2 class="text-xl text-primary font-semibold">- leaderboard</h2>
        </div>
      </div>

       <div class="form-container mt-5 space-y-6 md:hidden" style="width: 320px;">
        <table class="border-2 w-full border-gray-200">
          <tbody>
          <tr class="bg-primary text-white text-center">
            <th class="py-2 border-r-2 border-gray-300">Name</th>
            <th class="py-2">Answers</th>
          </tr>

          <tr *ngIf="textAnswers.length == 0">
            <td colspan="2" class="py-4 text-center">No answers found</td>
          </tr>

         <ng-container *ngFor="let answer of textAnswers; let i = index;">
            <tr [class]="i % 2 == 0 ? 'text-center' : 'bg-gray-200 text-center'">
              <td class="py-2 border-r-2 border-gray-300">{{answer.answer_author}}</td>
              <td class="py-2">
                <div class="flex flex-col gap-2">
                  <p *ngFor="let qnAnswer of answer.answers">{{qnAnswer}}</p>
                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
       </div>

      <div class="form-container mt-5 space-y-6 hidden md:block" style="width: 740px;">
        <table class="border-2 w-full border-gray-200">
          <tbody>
          <tr class="bg-primary text-white text-center">
            <th class="py-2 border-r-2 border-gray-300">Name</th>
            <th class="py-2 border-r-2 border-gray-300">Answers</th>
          </tr>

          <ng-container *ngFor="let answer of textAnswers; let i = index;">
            <tr [class]="i % 2 == 0 ? 'text-center' : 'bg-gray-200 text-center'">
              <td class="py-2 border-r-2 border-gray-300">{{answer.answer_author}}</td>
              <td class="py-2">
                <div class="flex flex-col gap-2">
                  <p *ngFor="let qnAnswer of answer.answers">{{qnAnswer}}</p>
                </div>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="textAnswers.length == 0">
            <td colspan="2" class="py-4 text-center">No answers found</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <app-footer></app-footer>
</div>
