import { Component } from '@angular/core';
import {ApiMessageService} from "../services/api-message/api-message.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiMessage} from "../models/api_response";
import {FormBuilder, Validators} from "@angular/forms";
import {LoginService} from "../services/login.service";
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  constructor(
    private apiMessageService: ApiMessageService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private loginService: LoginService,
    private apiService: ApiService,
    private router: Router
  ) {}

  loginForm = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  get username() { return this.loginForm.get('username'); }
  get password() { return this.loginForm.get('password'); }

  async onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    const apiMessage = await this.loginService.login(
      this.username?.value || '',
      this.password?.value || ''
    );

    if (apiMessage.isSuccess) {
      this.loginForm.reset();
      await this.apiService.showGreetings();
      await this.router.navigate(['/show_invites']);
      return;
    }

    this.apiMessageService.setApiMessage(apiMessage);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
        // Access specific query parameter
        const message = params?.['message'];
        if (message == null || message == '') {
          return;
        }

        let status = params?.['status'];
        if (status == null || status == '') {
          status = 'success';
        }

        const apiMessage: ApiMessage = {
          isSuccess: status == 'success',
          message: message
        }

        this.apiMessageService.setApiMessage(apiMessage);
      });
  }

  async guestLogin() {
     const apiMessage = await this.loginService.guestLogin();

    if (apiMessage.isSuccess) {
      await this.apiService.showGreetings();
      await this.router.navigate(['/show_invites']);
    }

    this.apiMessageService.setApiMessage(apiMessage);
  }
}
