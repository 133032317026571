<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop (used to position the menu correctly, for some reason)-->
<div class="md:justify-center hidden md:flex">
  <div style="width: 1440px;" class="bg-black">
  </div>
</div>

<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <form [formGroup]="questionForm">
    <div class="flex justify-center">
    <div>
      <div style="max-width: 741px;">
        <div class="flex space-x-2">
          <h2 class="text-xl text-primary font-semibold border-b border-gray-600 pb-3">You have</h2>
          <h2 class="text-xl text-primary font-semibold">chosen Quiz</h2>
        </div>
      </div>

      <div class="form-container p-5 mt-5 space-y-6">

        <!-- Question text -->
        <div class="space-y-1">

          <label class="text-gray-700">What is your quiz based question?</label>

          <div class="relative">
            <textarea rows="3"
            formControlName="question_text"
            class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50"></textarea>
            <div class="absolute inset-y-0 right-0 flex items-start pr-3 pointer-events-none">
              <svg class="h5 w-5 text-primary" stroke="currentColor" width="36" height="36" viewBox="0 0 36 36"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M34 23.3333C34 24.2763 33.6254 25.1807 32.9586 25.8475C32.2918 26.5143 31.3874 26.8889 30.4444 26.8889H9.11111L2 34V5.55556C2 4.61256 2.3746 3.70819 3.0414 3.0414C3.70819 2.3746 4.61256 2 5.55556 2H30.4444C31.3874 2 32.2918 2.3746 32.9586 3.0414C33.6254 3.70819 34 4.61256 34 5.55556V23.3333Z"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>

          <div class="h-1" >
              <div *ngIf="questionText?.errors?.['required'] && (questionText?.touched || questionText?.dirty)">
                <small class="text-red-500">Question text is required</small>
              </div>
              <div *ngIf="questionText?.errors?.['unknown'] && (questionText?.touched || questionText?.dirty)">
                <small class="text-red-500">Network error</small>
              </div>
              <div *ngIf="questionText?.errors?.['question_text'] && (questionText?.touched || questionText?.dirty)"
                   class="flex flex-col gap-1">
                <small *ngFor="let error of questionText?.errors?.['question_text']"
                       class="text-red-500 inline-block">{{error}}</small>
              </div>
            </div>
        </div>
        <!-- End Question text -->

        <!-- Correct Answer -->
        <div class="space-y-1">
          <label class="text-gray-700">What is the correct answer?</label>

          <div class="relative">
            <input
              type="text"
              formControlName="correct_answer"
              class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50"
            />
            <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg class="h-5 w-5 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <!-- Replace the contents of this SVG element with your desired checkmark icon SVG code -->
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          </div>

            <div class="h-1" >
              <div *ngIf="correctAnswer?.errors?.['required'] && (correctAnswer?.touched || correctAnswer?.dirty)">
                <small class="text-red-500">Correct answer is required</small>
              </div>
              <div *ngIf="correctAnswer?.errors?.['unknown'] && (correctAnswer?.touched || correctAnswer?.dirty)">
                <small class="text-red-500">Network error</small>
              </div>
              <div *ngIf="correctAnswer?.errors?.['correct_answer'] && (correctAnswer?.touched || correctAnswer?.dirty)"
                   class="flex flex-col gap-1">
                <small *ngFor="let error of correctAnswer?.errors?.['correct_answer']"
                       class="text-red-500 inline-block">{{error}}</small>
              </div>
          </div>

        </div>
        <!-- End Correct Answer -->


        <!-- Wrong Answer -->
        <div class="space-y-1">

          <label class="text-gray-700">What is the first wrong answer?</label>

          <div class="relative">
            <input
              type="text"
              formControlName="wrong_answer"
              class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50"
            />
            <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg class="h-5 w-5 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <!-- Replace the contents of this SVG element with your desired cross icon SVG code -->
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>

           <div class="h-1" >
              <div *ngIf="wrongAnswer?.errors?.['required'] && (wrongAnswer?.touched || wrongAnswer?.dirty)">
                <small class="text-red-500">Wrong answer is required</small>
              </div>
              <div *ngIf="wrongAnswer?.errors?.['unknown'] && (wrongAnswer?.touched || wrongAnswer?.dirty)">
                <small class="text-red-500">Network error</small>
              </div>
              <div *ngIf="wrongAnswer?.errors?.['wrong_answer'] && (wrongAnswer?.touched || wrongAnswer?.dirty)"
                   class="flex flex-col gap-1">
                <small *ngFor="let error of wrongAnswer?.errors?.['wrong_answer']"
                       class="text-red-500 inline-block">{{error}}</small>
              </div>
          </div>

        </div>
        <!-- End Wrong Answer -->

        <!-- Wrong Answer 2-->
        <div class="space-y-1">

          <label class="text-gray-700">What is the second wrong answer?</label>

          <div class="relative">
            <input
              formControlName="wrong_answer_2"
              type="text"
              class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50"
            />
            <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg class="h-5 w-5 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>

          <div class="h-1" >
              <div *ngIf="wrongAnswer2?.errors?.['required'] && (wrongAnswer2?.touched || wrongAnswer2?.dirty)">
                <small class="text-red-500">Wrong answer 2 is required</small>
              </div>
              <div *ngIf="wrongAnswer2?.errors?.['unknown'] && (wrongAnswer2?.touched || wrongAnswer2?.dirty)">
                <small class="text-red-500">Network error</small>
              </div>
              <div *ngIf="wrongAnswer2?.errors?.['wrong_answer_2'] && (wrongAnswer2?.touched || wrongAnswer2?.dirty)"
                   class="flex flex-col gap-1">
                <small *ngFor="let error of wrongAnswer2?.errors?.['wrong_answer_2']"
                       class="text-red-500 inline-block">{{error}}</small>
              </div>
          </div>

        </div>
        <!-- End Wrong Answer 2-->


        <!-- Question timer seconds-->
        <div class="space-y-1">

          <label class="text-gray-700">How many seconds does a user have to answer a question (30 to 60 seconds)</label>

          <div class="relative">
            <input
              type="text"
              formControlName="question_timer_seconds"
              class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50"
            />
            <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg class="h-5 w-5 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <!-- Replace the contents of this SVG element with your desired clock icon SVG code -->
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6l4 2"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6l-4 2"
                />
                <circle cx="12" cy="12" r="10"/>
              </svg>
            </div>
          </div>

           <div class="h-1" >
              <div *ngIf="questionTimerSeconds?.errors?.['required'] && (questionTimerSeconds?.touched || questionTimerSeconds?.dirty)">
                <small class="text-red-500">Question timer is required</small>
              </div>
              <div *ngIf="questionTimerSeconds?.errors?.['unknown'] && (questionTimerSeconds?.touched || questionTimerSeconds?.dirty)">
                <small class="text-red-500">Network error</small>
              </div>
              <div *ngIf="questionTimerSeconds?.errors?.['question_timer_seconds'] && (questionTimerSeconds?.touched || questionTimerSeconds?.dirty)"
                   class="flex flex-col gap-1">
                <small *ngFor="let error of questionTimerSeconds?.errors?.['question_timer_seconds']"
                       class="text-red-500 inline-block">{{error}}</small>
              </div>
          </div>

        </div>
        <!-- End Question timer seconds-->


        <!-- Game minutes seconds-->
        <div class="space-y-1 pb-5">

          <label class="text-gray-700">How many minutes should the game last? (15 to 60 minutes)</label>

          <div class="relative">
            <input
              type="text"
              formControlName="end_timer_minutes"
              class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50"
            />
            <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg class="h-5 w-5" width="30" height="50" viewBox="0 0 30 50" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 0L0.0250006 15L10 25L0.0250006 35.025L0 50H30V35L20 25L30 15.025V0H0ZM25 36.25V45H5V36.25L15 26.25L25 36.25Z"
                  fill="#5F9AD2"/>
              </svg>
            </div>
          </div>

          <div class="h-1" >
              <div *ngIf="endTimerMinutes?.errors?.['required'] && (endTimerMinutes?.touched || endTimerMinutes?.dirty)">
                <small class="text-red-500">Question timer is required</small>
              </div>
              <div *ngIf="endTimerMinutes?.errors?.['unknown'] && (endTimerMinutes?.touched || endTimerMinutes?.dirty)">
                <small class="text-red-500">Network error</small>
              </div>
              <div *ngIf="endTimerMinutes?.errors?.['end_timer_minutes'] && (endTimerMinutes?.touched || endTimerMinutes?.dirty)"
                   class="flex flex-col gap-1">
                <small *ngFor="let error of endTimerMinutes?.errors?.['end_timer_minutes']"
                       class="text-red-500 inline-block">{{error}}</small>
              </div>
          </div>
        </div>

      </div>
      <div class="mt-4">
        <div class="flex justify-between pb-4">
          <button
            routerLink="/ask_question"
            class="inline-block bg-primary text-white p-2 rounded-md text-sm px-4 active:ring focus:ring hover:text-gray-200 active:text-gray-100">
            Back
          </button>

          <button
            (click)="onSubmit()"
            class="inline-block bg-primary text-white p-2 rounded-md text-sm px-4 active:ring focus:ring hover:text-gray-200 active:text-gray-100">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
  </form>

  <!-- Footer -->
  <app-footer></app-footer>
</div>
