<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop (used to position the menu correctly, for some reason)-->
<div class="md:justify-center hidden md:flex">
  <div style="width: 1440px;" class="bg-black">
  </div>
</div>

<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <form [formGroup]="resetPasswordStartForm">
    <div class="flex justify-center">
      <div>
        <div style="max-width: 741px;">
          <div class="flex space-x-2">
            <h2 class="text-xl text-primary font-semibold border-b border-gray-600 pb-3">Reset</h2>
            <h2 class="text-xl text-primary font-semibold">password</h2>
          </div>
        </div>
        <div class="form-container px-5 py-24 mt-5 space-y-6 w-80 md:w-96">
          <div>
            <img ngSrc="assets/img/home/Nominate%20Logo.png" alt="Nominate Logo" class="mx-auto w-1/2" height="78"
                 width="240"/>
          </div>

          <!-- Email Field -->
          <div>
            <div class="relative">
              <input
                type="text"
                placeholder="Email"
                class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50 text-center"
                formControlName="email"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg fill="#4A8DCB" class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/>
                </svg>
              </div>
            </div>
            <div class="h-1 text-center">
              <div *ngIf="email?.errors?.['required'] && (email?.touched || email?.dirty)">
                <small class="text-red-500">Email is required</small>
              </div>
              <div *ngIf="email?.errors?.['unknown'] && (email?.touched || email?.dirty)">
                <small class="text-red-500">Network error</small>
              </div>
              <div *ngIf="email?.errors?.['email'] && (email?.touched || email?.dirty)"
                   class="flex flex-col gap-1">
                <small *ngFor="let error of email?.errors?.['email']"
                       class="text-red-500 inline-block">{{error}}</small>
              </div>
            </div>
          </div>
          <!-- End Email Field -->

          <!-- Reset Password button -->
          <button
            (click)="onSubmit()"
            class="inline-block bg-primary text-white p-2 rounded-md text-sm px-4 active:ring focus:ring hover:text-gray-200 active:text-gray-100 w-full">
            Reset Password
          </button>

          <div class="space-y-2">
            <p style="font-size: .95rem;" class="text-center">Already have your password?
              <a routerLink="/login" class="text-primary">Sign In</a></p>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- Footer -->
  <app-footer></app-footer>
</div>
