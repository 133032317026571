import {Injectable} from '@angular/core';
import {WS_BASE_URL} from "./constants";
import {BehaviorSubject, filter, Observable, Subject, tap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class WsService {

  private webSocket: WebSocket | null = null;
  private wsMessages = new Subject<any>();
  private outgoingMessages = new Subject<string>();

  private filterMessagesByType(type: string): Observable<any> {
    return this.wsMessages.pipe(
      filter((message: any) => message.type == type)
    );
  }

  get onlineUsers () {
    return this.filterMessagesByType('online_users');
  }

  get invites () {
    return this.filterMessagesByType('invites');
  }

  get invite () {
    return this.filterMessagesByType('invite');
  }

  constructor() {
    this.initWebSocket();
    this.outgoingMessages.subscribe((message: string) => {
      this.webSocket?.send(message);
    });
  }

  private initWebSocket(){
    this.webSocket = new WebSocket(WS_BASE_URL);
    this.webSocket.onopen = () => {
      console.log('connected');
    }
    this.webSocket.onmessage = (e) => {
      this.wsMessages.next(JSON.parse(e.data));
      console.log(e.data);
    }
    this.webSocket.onerror = (e) => {
      console.log(e);
    }
    this.webSocket.onclose = (e) => {
      console.log(e);
    }
  }

  // private checkWebSocket() {
  //   if (!this.webSocket) {
  //     try {
  //       this.initWebSocket();
  //     } catch (e) {
  //       console.log(e);
  //       this.webSocket = null;
  //     }
  //   }
  // }

  sendMessage(message: string) {
    // this.checkWebSocket();
    this.outgoingMessages.next(message);
  }
}
