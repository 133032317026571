import { Component } from '@angular/core';
import {LoginService} from "../services/login.service";
import {Router} from "@angular/router";
import {ApiMessageService} from "../services/api-message/api-message.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private apiMessageService: ApiMessageService) {
  }

  ngOnInit(): void {
    this.loginService.logout();
    this.apiMessageService.setApiMessage({
        isSuccess: true,
        message: 'Logout successful.'
    });
    this.router.navigate(['/login']);
  }
}
