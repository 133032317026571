import { Injectable } from '@angular/core';
import {API_GUEST_TOKEN, API_PING, API_TOKEN, API_TOKEN_REFRESH} from "./constants";
import {LoaderService} from "./loader/loader.service";
import {ApiMessage} from "../models/api_response";
import {PingServiceService} from "./ping-service.service";
import {Router} from "@angular/router";
import {ApiMessageService} from "./api-message/api-message.service";

const ACCESS_TOKEN_KEY = 'access';
const REFRESH_TOKEN_KEY = 'refresh';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private loaderService: LoaderService,
    private pingService: PingServiceService,
    private router: Router,
    private apiMessageService: ApiMessageService
  ) { }

  logout(): void {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    this.pingService.stopPings();
  }

  get accessToken(): string {
    let token = localStorage.getItem(ACCESS_TOKEN_KEY) || '';
    return token.trim();
  }

  async refreshAccessToken(showLoader: boolean = true): Promise<boolean> {
    try {
      if (showLoader) {
        this.loaderService.setLoading(true);
      }

      const refreshToken = (localStorage.getItem(REFRESH_TOKEN_KEY) || '').trim();
      const accessToken = (localStorage.getItem(ACCESS_TOKEN_KEY) || '').trim();
      if (refreshToken == '' || accessToken == '') {
        this.logout();
        await this.router.navigate(['/login']);
        this.apiMessageService.setApiMessage({message: 'Session expired. Please login again.', isSuccess: false});
        if (showLoader) {
          this.loaderService.setLoading(false);
        }
        return false;
      }

      // access token expired. try to refresh
      const refreshResponse = await fetch(API_TOKEN_REFRESH, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${refreshToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refresh: refreshToken })
      });

      // refresh token expired. logout
      if (showLoader) {
        this.loaderService.setLoading(false);
      }

      if (refreshResponse.ok) {
        const result = await refreshResponse.json();
        localStorage.setItem(ACCESS_TOKEN_KEY, result.access);
        return true;
      }

      this.logout();
      await this.router.navigate(['/login']);
      this.apiMessageService.setApiMessage({message: 'Session expired. Please login again.', isSuccess: false});
      return false;
    } catch (e) {
      console.error(e);
      this.logout();
      await this.router.navigate(['/login']);
      this.apiMessageService.setApiMessage({message: 'Session expired. Please login again.', isSuccess: false});
      if (showLoader) {
        this.loaderService.setLoading(false);
      }
      return false;
    }
  }

  get refreshToken(): string {
    let token = localStorage.getItem(REFRESH_TOKEN_KEY) || '';
    return token.trim();
  }

  get isLoggedIn(): boolean {
    return localStorage.getItem(ACCESS_TOKEN_KEY) != null &&
      localStorage.getItem(REFRESH_TOKEN_KEY) != null;
  }

  async guestLogin() {
    try {
       this.loaderService.setLoading(true);
       const response = await fetch(API_GUEST_TOKEN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      this.loaderService.setLoading(false);

      if (response.ok) {
        const json = await response.json();
        localStorage.setItem(ACCESS_TOKEN_KEY, json.access);
        localStorage.setItem(REFRESH_TOKEN_KEY, json.refresh);

        this.pingService.startPings();

        return {
          isSuccess: true,
          message: 'Login successful.'
        }
      } else {
        const json = await response.json();
        return {
          isSuccess: false,
          message: json.detail
        }
      }
    } catch (e) {
      this.loaderService.setLoading(false);
      console.error(e);
      return {
        isSuccess: false,
        message: 'An error occurred while logging in.'
      }
    }
  }

  async login(username: string, password: string): Promise<ApiMessage> {
    try {
      this.loaderService.setLoading(true);
      const body = {username, password};
      const response = await fetch(API_TOKEN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      this.loaderService.setLoading(false);

      if (response.ok) {
        const json = await response.json();
        localStorage.setItem(ACCESS_TOKEN_KEY, json.access);
        localStorage.setItem(REFRESH_TOKEN_KEY, json.refresh);

        this.pingService.startPings();

        return {
          isSuccess: true,
          message: 'Login successful.'
        }
      } else {
        const json = await response.json();
        return {
          isSuccess: false,
          message: json.detail
        }
      }
    } catch (e) {
      this.loaderService.setLoading(false);
      console.error(e);
      return {
        isSuccess: false,
        message: 'An error occurred while logging in.'
      }
    }
  }
}
