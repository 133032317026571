import {Injectable} from '@angular/core';
import {LoaderService} from "./loader/loader.service";
import {LoginService} from "./login.service";
import {ApiRequest} from "../models/api-request.model";
import {ApiMessageService} from "./api-message/api-message.service";
import {
  API_ANSWER_QUESTION,
  API_INVITES, API_ME,
  API_NOMINATE_USER,
  API_ONLINE_USERS,
  API_OPEN_INVITE, API_PING, API_QUESTIONS_LIST, API_QUIZ_ANSWERS,
  API_QUIZ_QUESTION,
  API_QUIZ_QUESTION_VALIDATE, API_TEXT_ANSWERS, API_TEXT_QUESTION, API_TEXT_QUESTION_VALIDATE
} from "./constants";
import {OnlineUser} from "../models/online-user.model";
import {QuizQuestionRequest} from "../quiz-question-form/models";
import {ValidationErrors} from "@angular/forms";
import {Router} from "@angular/router";
import {Invite} from "../show-invites/models";
import {TextQuestionRequest} from "../text-question-form/models";
import {Question} from "../models/question-model";
import {QuizQuestionAnswerModel} from "../models/quiz-question-answer.model";
import {TextQuestionAnswerModel} from "../models/text-question-answer.model";
import {MeModel} from "../models/me.model";
import {ApiMessage} from "../models/api_response";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private loaderService: LoaderService,
    private loginService: LoginService,
    private apiMessageService: ApiMessageService,
    private router: Router
  ) {
  }

  async refreshToken(apiRequest: ApiRequest) {
    return await this.loginService.refreshAccessToken(apiRequest.showLoader);
  }

  async nominateRandomUser(questionType: string, questionId: number) {
    const apiRequest: ApiRequest = {
      url: API_NOMINATE_USER + questionType + '/' + questionId + '/',
      method: 'POST',
      showLoader: true,
      body: null,
      headers: {}
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      return;
    }

    if (response.ok) {
      const result = await response.json();
      const message = result['message'];
      this.apiMessageService.setApiMessage({message, isSuccess: true});
      return;
    }

    if (response.status == 400) {
      const result = await response.json();
      this.apiMessageService.setApiMessage({message: result['message'], isSuccess: false});
      await this.router.navigate(['show_invites']);
      return;
    }

    console.error(await response.text());
    this.apiMessageService.setApiMessage({message: 'Error nominating user', isSuccess: false});
  }

  async nominateUser(username: string, questionType: string, questionId: number) {
    const apiRequest: ApiRequest = {
      url: API_NOMINATE_USER + questionType + '/' + questionId + '/' + username + '/',
      method: 'POST',
      showLoader: true,
      body: null,
      headers: {}
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      return;

    }

    if (response.ok) {
      const result = await response.json();
      const message = result['message'];
      await this.router.navigate(['show_invites']);
      this.apiMessageService.setApiMessage({message, isSuccess: true});
      return;
    }

    if (response.status == 400) {
      const result = await response.json();
      this.apiMessageService.setApiMessage({message: result['message'], isSuccess: false});
      return;
    }

    console.error(await response.text());
    this.apiMessageService.setApiMessage({message: 'Error nominating user', isSuccess: false});
  }

  async makeRequest(apiRequest: ApiRequest): Promise<Response | null> {
    const headers = {
      ...apiRequest.headers,
      'Authorization': `Bearer ${this.loginService.accessToken}`,
    }

    if (apiRequest.showLoader) {
      this.loaderService.setLoading(true);
    }

    try {
      const response = await fetch(apiRequest.url, {
        method: apiRequest.method,
        headers: headers,
        body: apiRequest.body
      });

      if (apiRequest.showLoader) {
        this.loaderService.setLoading(false);
      }

      if (response.status == 401) {
        const refreshTokenResult = await this.refreshToken(apiRequest);
        if (!refreshTokenResult) {
          return null;
        }
        return await this.makeRequest(apiRequest);
      }
      return response;
    } catch (e) {
      if (apiRequest.showLoader) {
        this.loaderService.setLoading(false);
      }

      console.error(e);

      this.apiMessageService.setApiMessage({
        message: 'There was a network error.',
        isSuccess: false
      });
      return null;
    }
  }


  async getOnlineUsers(questionType: string, questionId: number, showLoading: boolean): Promise<OnlineUser[]> {
    const apiRequest: ApiRequest = {
      url: API_ONLINE_USERS + questionType + '/' + questionId + '/',
      method: 'GET',
      showLoader: showLoading,
      body: null,
      headers: {}
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      return [];
    }

    if (response.ok) {
      const envelop = await response.json();
      return envelop['users'];
    }

    if (response.status == 400) {
      const result = await response.json();
      this.apiMessageService.setApiMessage({message: result['message'], isSuccess: false});
      return [];
    }

    this.apiMessageService.setApiMessage({message: 'Error getting online users', isSuccess: false});

    console.log(await response.text());
    return [];
  }

  async askQuizQuestionValidate(quizQuestion: QuizQuestionRequest): Promise<ValidationErrors | null> {
    const apiRequest: ApiRequest = {
      url: API_QUIZ_QUESTION_VALIDATE,
      method: 'POST',
      showLoader: false,
      body: JSON.stringify(quizQuestion),
      headers: {
        'Content-Type': 'application/json',
      }
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      return {unknown: 'Unknown error'};
    }

    if (response.ok) {
      return null;
    }
    return await response.json();
  }

  async askQuizQuestion(quizQuestion: QuizQuestionRequest) {
    const apiRequest: ApiRequest = {
      url: API_QUIZ_QUESTION,
      method: 'POST',
      showLoader: true,
      body: JSON.stringify(quizQuestion),
      headers: {
        'Content-Type': 'application/json',
      }
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      this.apiMessageService.setApiMessage({
        message: "Error creating question. Please try again.",
        isSuccess: false
      });

      this.loaderService.setLoading(false);
    }

    if (response?.ok) {
      const createdQuestion = await response.json();
      const questionId = createdQuestion?.id;
      const questionType = 'quiz';
      await this.router.navigate(['nominate_user', questionType, questionId]);
    } else {
      this.apiMessageService.setApiMessage({
        message: "Error creating question. Please try again.",
        isSuccess: false
      });
    }
  }

  async openInvite(questionType: string, inviteId: number): Promise<boolean> {
    const apiRequest: ApiRequest = {
      url: API_OPEN_INVITE + questionType + '/' + inviteId + '/',
      method: 'POST',
      showLoader: true,
      body: null,
      headers: {}
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      this.apiMessageService.setApiMessage({
        isSuccess: false,
        message: 'There was a network error. Try again later.'
      });
      return false;
    }

    if (response.ok) {
      return true;
    }
    // console.error(await response.text());
    const result = await response.json();
    let message = 'Failed to open invite. Try again later.';
    if (result.message) {
      message = result.message;
    }

    this.apiMessageService.setApiMessage({
      isSuccess: false,
      message: message
    });
    return false;
  }


  async getInvites(showLoader: boolean): Promise<Invite[]> {
    const apiRequest: ApiRequest = {
      url: API_INVITES,
      method: 'GET',
      showLoader: showLoader,
      body: null,
      headers: {}
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      return [];
    }

    if (response.ok) {
      const envelope = await response.json();
      return envelope.invites;
    }
    this.apiMessageService.setApiMessage({
      message: 'Failed to get invites. Try again later.',
      isSuccess: false
    });
    return [];
  }

  async getMe(): Promise<MeModel | null> {
    const apiRequest: ApiRequest = {
      url: API_ME,
      method: 'GET',
      showLoader: true,
      body: null,
      headers: {}
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      return null;
    }

    if (response.ok) {
      return await response.json();
    }
    this.apiMessageService.setApiMessage({
      message: 'Failed to load username',
      isSuccess: false
    });
    return null;
  }


  async askTextQuestionValidate(textQuestion: TextQuestionRequest): Promise<ValidationErrors | null> {
    const apiRequest: ApiRequest = {
      url: API_TEXT_QUESTION_VALIDATE,
      method: 'POST',
      showLoader: false,
      body: JSON.stringify(textQuestion),
      headers: {
        'Content-Type': 'application/json',
      }
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      return {unknown: 'Unknown error'};
    }

    if (response.ok) {
      return null;
    }
    return await response.json();
  }

  async askTextQuestion(textQuestion: TextQuestionRequest) {
    const apiRequest: ApiRequest = {
      url: API_TEXT_QUESTION,
      method: 'POST',
      showLoader: true,
      body: JSON.stringify(textQuestion),
      headers: {
        'Content-Type': 'application/json',
      }
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      this.apiMessageService.setApiMessage({
        message: "Error creating question. Please try again.",
        isSuccess: false
      });
      return;
    }

    if (response?.ok) {
      const createdQuestion = await response.json();
      const questionId = createdQuestion?.id;
      const questionType = 'text';
      await this.router.navigate(['nominate_user', questionType, questionId]);
    } else {
      this.apiMessageService.setApiMessage({
        message: "Error creating question. Please try again.",
        isSuccess: false
      });
    }
  }


  async ping() {
    const apiRequest: ApiRequest = {
      url: API_PING,
      method: 'POST',
      showLoader: false,
      body: null,
      headers: {}
    }

    await this.makeRequest(apiRequest);
  }


  async getInvite(questionType: string, inviteId: number, showLoader: boolean = true): Promise<Invite | null> {
    const apiRequest: ApiRequest = {
      url: API_INVITES + questionType + '/' + inviteId + '/',
      method: 'GET',
      showLoader: showLoader,
      body: null,
      headers: {}
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      return null;
    }

    if (response.ok) {
      return await response.json();
    }
    console.error(await response.text());
    this.apiMessageService.setApiMessage({
      isSuccess: false,
      message: 'Failed to get invite. Try again later.'
    });
    return null;
  }

  async showGreetings() {
    const me = await this.getMe();
    if (me) {
      const apiMessage: ApiMessage = {
        isSuccess: true,
        message: 'Welcome ' + me.username
      }
      this.apiMessageService.setApiMessage(apiMessage);
    }
  }


  async answerQuestion(invite: Invite | null, formData: any): Promise<void> {
    const apiRequest: ApiRequest = {
      url: API_ANSWER_QUESTION + invite?.question_type + '/' + invite?.id + '/',
      method: 'POST',
      showLoader: true,
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      return;
    }

    if (response.ok) {
      this.apiMessageService.setApiMessage({
        isSuccess: true,
        message: 'Answered question successfully. Nominate another user to answer the question.'
      });
      await this.router.navigate(['nominate_user', invite?.question_type, invite?.question_id]);
      return;
    }

    const data = await response.text();
    let message = 'Failed to answer question. Try again later.';
    if (response.status === 400) {
      message = (JSON.parse(data)).message;
    }
    console.error(data);
    this.apiMessageService.setApiMessage({
      isSuccess: false,
      message
    });
  }

  async getQuestions(questionType: string): Promise<Question[]> {
    const apiRequest: ApiRequest = {
      url: API_QUESTIONS_LIST + questionType + '/',
      method: 'GET',
      showLoader: true,
      body: null,
      headers: {}
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      return [];
    }

    if (response.ok) {
      return await response.json();
    }

    console.error(await response.text());
    this.apiMessageService.setApiMessage({
      isSuccess: false,
      message: 'Failed to get questions. Try again later.'
    });
    return []
  }

  async getQuizAnswers(questionId: string): Promise<QuizQuestionAnswerModel[]> {
    const apiRequest: ApiRequest = {
      url: API_QUIZ_ANSWERS + questionId + '/',
      method: 'GET',
      showLoader: true,
      body: null,
      headers: {}
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      return [];
    }

    if (response.ok) {
      return await response.json();
    }

    console.error(await response.text());
    this.apiMessageService.setApiMessage({
      isSuccess: false,
      message: 'Failed to get answers. Try again later.'
    });
    return []
  }


  async getTextAnswers(questionId: string): Promise<TextQuestionAnswerModel[]> {
    const apiRequest: ApiRequest = {
      url: API_TEXT_ANSWERS + questionId + '/',
      method: 'GET',
      showLoader: true,
      body: null,
      headers: {}
    }

    const response = await this.makeRequest(apiRequest);
    if (response == null) {
      return [];
    }

    if (response.ok) {
      return await response.json();
    }

    console.error(await response.text());
    this.apiMessageService.setApiMessage({
      isSuccess: false,
      message: 'Failed to get questions. Try again later.'
    });
    return []
  }
}
