import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loadingSubject: Subject<boolean>;

  constructor() {
    this.loadingSubject = new BehaviorSubject<boolean>(false);
  }

  setLoading(loading: boolean) {
    this.loadingSubject.next(loading);
  }

  getLoading(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }
}
