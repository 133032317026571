import {Component} from '@angular/core';
import {Invite} from "../show-invites/models";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../services/api.service";
import {ApiMessageService} from "../services/api-message/api-message.service";
import {LoginService} from "../services/login.service";
import {WsService} from "../services/ws.service";

@Component({
  selector: 'app-answer-quiz-question',
  templateUrl: './answer-quiz-question.component.html',
  styleUrls: ['./answer-quiz-question.component.css']
})
export class AnswerQuizQuestionComponent {
  private inviteId: number = 0;
  private questionType: string = "";
  invite: Invite | null = null;
  invitesTimer: any = null;
  originallyOrderedAnswers: string[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private apiMessageService: ApiMessageService,
    private loginService: LoginService,
    private wsService: WsService
  ) {
  }

  requestInvite() {
    const message = {
      type: 'invite',
      token: this.loginService.accessToken,
      args: {
        invite_id: this.inviteId,
        question_type: 'quiz'
      }
    }
    this.wsService.sendMessage(JSON.stringify(message));
  }

  async ngOnInit() {
    this.inviteId = parseInt(this.activatedRoute.snapshot.params['inviteId']);
    this.questionType = 'quiz';

    const opened = await this.apiService.openInvite(this.questionType, this.inviteId);
    if (!opened) {
      await this.router.navigate(['/show_invites']);
    }

    this.invite = await this.apiService.getInvite(this.questionType, this.inviteId);
    this.originallyOrderedAnswers = this.invite?.answers || [];
    if (!this.invite) {
      await this.router.navigate(['/show_invites']);
    }

    this.invitesTimer = setInterval(() => this.requestInvite(), 1000);

    this.wsService.invite.subscribe((wsResponse: any) => {
      const invite: Invite = wsResponse.data;
      if (invite.question_type != 'quiz') {
        return;
      }

      this.invite = invite;

      if (this.invite?.remaining_question_time_seconds == 0) {
        this.apiMessageService.setApiMessage({
          message: "Question has timed out",
          isSuccess: false
        });
        this.router.navigate(['/show_invites']);
      }
    });
  }

  ngOnDestroy() {
    clearInterval(this.invitesTimer);
  }


  get remainingGameTime(): string {
    if (!this.invite) {
      return "0";
    }
    const minutes = Math.floor(this.invite.game_timeout);
    const seconds = Math.floor((this.invite.game_timeout - minutes) * 60);

    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    const secondsStr = seconds < 10 ? "0" + seconds : seconds;
    return minutesStr + ":" + secondsStr;
  }

  get remainingAnswerTime(): number {
    if (!this.invite) {
      return 0;
    }
    return Math.floor(this.invite.remaining_question_time_seconds);
  }

  async onSubmit(answer: string) {
    const formData = {answer}
    await this.apiService.answerQuestion(this.invite, formData);
  }
}
