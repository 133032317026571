<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop (used to position the menu correctly, for some reason)-->
<div class="md:justify-center hidden md:flex">
  <div style="width: 1440px;" class="bg-black">
  </div>
</div>


<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <!-- Why Nominate-->
  <div class="mx-auto" style="max-width: 620px;">
    <div class="flex space-x-2">
      <h2 class="text-xl text-primary font-semibold border-b border-gray-600 pb-1">Please</h2>
      <h2 class="text-xl text-primary font-semibold">answer the question below</h2>
    </div>
  </div>


  <div class="flex justify-center">
    <div class="image-stacks-container w-full md:w-3/5 mt-6 border-2 border-gray-300"
         style="background-color: rgba(74, 141, 203, 0.25);">
      <div class="flex justify-center">
        <div class="my-6">
          <div class="image-stack">
            <div class="flex justify-center">
              <img ngSrc="assets/img/answer_question/Question%20mark.png" alt="Image 1" height="591" width="550" class="h-80 w-80">
            </div>
            <p class="inner-text-content text-primary font-bold text-center px-10 w-full">
              {{invite?.question_text}}
            </p>
          </div>
          <form class="space-y-4 mx-4 md:mx-0" [formGroup]="answerForm">

            <!-- Answer 0 Field -->
            <div>
              <div class="space-y-2">
                <label>Enter answer <span *ngIf="showAnswer1Number">1</span></label>
                <input
                  type="text"
                  class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50"
                  formControlName="answer_0"
                />
              </div>
              <div>
                <div *ngIf="answer0Required && (answer_0?.touched || answer_0?.dirty)">
                  <small class="text-red-500">This field is required</small>
                </div>
              </div>
            </div>
            <!-- End Answer 0 Field -->

            <!-- Answer 1 Field -->
            <div *ngIf="showAnswer_1">
              <div class="space-y-2">
                <label>Enter answer 2</label>
                <input
                  type="text"
                  class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50"
                  formControlName="answer_1"
                />
              </div>
              <div>
                <div *ngIf="answer1Required && (answer_1?.touched || answer_1?.dirty)">
                  <small class="text-red-500">This field is required</small>
                </div>
              </div>
            </div>
            <!-- End Answer 1 Field -->

            <!-- Answer 2 Field -->
            <div *ngIf="showAnswer_2">
              <div class="space-y-2">
                <label>Enter answer 3</label>
                <input
                  type="text"
                  class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50"
                  formControlName="answer_2"
                />
              </div>
              <div>
                <div *ngIf="answer2Required && (answer_2?.touched || answer_2?.dirty)">
                  <small class="text-red-500">This field is required</small>
                </div>
              </div>
            </div>
            <!-- End Answer 2 Field -->

            <div class="flex justify-between">
              <button
                (click)="onSubmit()"
                class="inline-block bg-primary text-white p-2 rounded-md text-sm px-4 active:ring focus:ring hover:text-gray-200 active:text-gray-100">
                Back
              </button>

              <button
                (click)="onSubmit()"
                class="inline-block bg-primary text-white p-2 rounded-md text-sm px-4 active:ring focus:ring hover:text-gray-200 active:text-gray-100">
                Next
              </button>
            </div>

          </form>
        </div>
      </div>
      <div class="flex justify-between px-4 md:px-20 py-5">
        <div class="space-y-2">
          <p class="font-semibold text-primary">Game Timer</p>
          <div class="image-stack-2">
            <img ngSrc="assets/img/answer_question/Game%20clock.png" alt="Image 1" height="91" width="76">
            <p class="inner-game-clock-content text-gray-600 font-bold text-center px-2">
              {{remainingGameTime}}
            </p>
          </div>
        </div>
        <div class="space-y-2">
          <p class="font-semibold text-primary">Quiz Timer</p>
          <div class="image-stack-2">
            <img ngSrc="assets/img/answer_question/Question%20timer.png" alt="Image 1" height="87" width="81">
            <p class="inner-quiz-clock-content text-gray-600 font-bold text-center px-2">
              {{remainingAnswerTime}}s
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- Footer -->
  <app-footer></app-footer>
</div>
