import { Component } from '@angular/core';
import {QuizQuestionAnswerModel} from "../models/quiz-question-answer.model";
import {ApiService} from "../services/api.service";
import {ActivatedRoute} from "@angular/router";
import {TextQuestionAnswerModel} from "../models/text-question-answer.model";

@Component({
  selector: 'app-text-question-answers',
  templateUrl: './text-question-answers.component.html',
  styleUrls: ['./text-question-answers.component.css']
})
export class TextQuestionAnswersComponent {
  textAnswers: TextQuestionAnswerModel[] = [];

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {

      const questionId = params['questionId'];
      this.apiService.getTextAnswers(questionId).then((data) => {
        this.textAnswers = data;
      });

    });
  }
}
