import { Component } from '@angular/core';
import {API_VERIFY_EMAIL} from "../services/constants";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../services/loader/loader.service";
import {ApiMessageService} from "../services/api-message/api-message.service";

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private apiMessageService: ApiMessageService,
    private router: Router
  ) {
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      const token = params['token'] || 'no-token';
      this.verifyEmail(token);
    });
  }

  async verifyEmail(token: string) {
    this.loaderService.setLoading(true);

    const url = API_VERIFY_EMAIL + token + '/';
    try {
      const response = await fetch(url, {
        method: 'POST',
      });

      this.loaderService.setLoading(false);

      if (response.ok) {
        this.apiMessageService.setApiMessage({
          isSuccess: true,
          message: 'Email verified successfully. Please login.'
        });
      } else {
        const data = await response.json();
        this.apiMessageService.setApiMessage({
          isSuccess: false, message: data.message
        });
      }
    } catch (e) {
      console.error(e);
      this.apiMessageService.setApiMessage({
        isSuccess: false, message: 'Something went wrong. Please try again.'
      });
    }

    await this.router.navigate(['/login']);
  }
}
