import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {ApiMessage} from "../../models/api_response";

@Injectable({
  providedIn: 'root'
})
export class ApiMessageService {
  private apiMessageSubject: Subject<ApiMessage>

  constructor() {
    this.apiMessageSubject = new BehaviorSubject<ApiMessage>({isSuccess: false, message: ''});
  }

  get apiMessage(): Observable<ApiMessage> {
    return this.apiMessageSubject.asObservable();
  }

  setApiMessage(apiMessage: ApiMessage) {
    this.apiMessageSubject.next(apiMessage);
  }

  clearApiMessage() {
    this.apiMessageSubject.next({isSuccess: false, message: ''});
  }
}
