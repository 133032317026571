import { Component } from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormBuilder, ValidationErrors, Validators} from "@angular/forms";
import {TextQuestionFormService} from "./text-question-form.service";
import {TextQuestionRequest} from "./models";
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-text-question-form',
  templateUrl: './text-question-form.component.html',
  styleUrls: ['./text-question-form.component.css']
})
export class TextQuestionFormComponent {

  constructor(
    private fb: FormBuilder,
    private textQuestionFormService: TextQuestionFormService,
    private apiService: ApiService,
  ) { }

  questionTextValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.textQuestionFormService.validateField({question_text: control.value});
  };

  questionTimerSecondsValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.textQuestionFormService.validateField({question_timer_seconds: control.value});
  };

  endTimerMinutesValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.textQuestionFormService.validateField({end_timer_minutes: control.value});
  };

  numberOfAnswersValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.textQuestionFormService.validateField({number_of_answers: control.value});
  };

  questionForm = this.fb.group({
    question_text: ['', [Validators.required], [this.questionTextValidator]],
    question_timer_seconds: ['', [Validators.required], [this.questionTimerSecondsValidator]],
    end_timer_minutes: ['', [Validators.required], [this.endTimerMinutesValidator]],
    number_of_answers: ['', [Validators.required], [this.numberOfAnswersValidator]],
  });

  get questionText() { return this.questionForm.get('question_text'); }
  get questionTimerSeconds() { return this.questionForm.get('question_timer_seconds'); }
  get endTimerMinutes() { return this.questionForm.get('end_timer_minutes'); }
  get numberOfAnswers() { return this.questionForm.get('number_of_answers'); }

  async onSubmit() {
    if (this.questionForm.invalid) {
      return;
    }

    const textQuestionRequest: TextQuestionRequest = {
      question_text: this.questionText?.value || '',
      question_timer_seconds: parseInt(this.questionTimerSeconds?.value || '0'),
      end_timer_minutes: parseInt(this.endTimerMinutes?.value || '0'),
      number_of_answers: parseInt(this.numberOfAnswers?.value || '0')
    };

    await this.apiService.askTextQuestion(textQuestionRequest);
  }
}
