import { Component } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {RegisterAccountService} from "./register-account.service";
import {ApiMessageService} from "../services/api-message/api-message.service";
import {LoginService} from "../services/login.service";
import {Router} from "@angular/router";
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-register-account',
  templateUrl: './register-account.component.html',
  styleUrls: ['./register-account.component.css']
})
export class RegisterAccountComponent {
  constructor(private fb: FormBuilder,
              private registerService: RegisterAccountService,
              private apiMessageService: ApiMessageService,
              private loginService: LoginService,
              private router: Router,
              private apiService: ApiService
  ) { }

  usernameValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.registerService.validateUsername(control.value);
  };

   passwordValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.registerService.validatePassword(control.value);
  };

  emailValidator: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return this.registerService.validateEmail(control.value);
  };

  registerForm = this.fb.group({
    username: ['', [Validators.required], [this.usernameValidator]],
    email: ['', [Validators.required], [this.emailValidator]],
    password: ['', [Validators.required], [this.passwordValidator]],
  });

  get username() { return this.registerForm.get('username'); }
  get email() { return this.registerForm.get('email'); }
  get password() { return this.registerForm.get('password'); }

  async onSubmit() {
    if (this.registerForm.invalid) {
      return;
    }
    const apiMessage = await this.registerService.registerAccount(
      this.username?.value || '',
      this.email?.value || '',
      this.password?.value || ''
    );
    this.apiMessageService.setApiMessage(apiMessage);
    if (apiMessage.isSuccess) {
      this.registerForm.reset();
    }
  }

    async guestLogin() {
     const apiMessage = await this.loginService.guestLogin();

    if (apiMessage.isSuccess) {
      await this.apiService.showGreetings();
      await this.router.navigate(['/show_invites']);
      return;
    }

    this.apiMessageService.setApiMessage(apiMessage);
  }
}
