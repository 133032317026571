import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgOptimizedImage} from "@angular/common";
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { MenuLinkComponent } from './menu/menu-link/menu-link.component';
import { AboutComponent } from './about/about.component';
import { AskQuestionComponent } from './ask-question/ask-question.component';
import { NominateUserComponent } from './nominate-user/nominate-user.component';
import { OnlineUsersComponent } from './online-users/online-users.component';
import { AnswerQuestionComponent } from './answer-question/answer-question.component';
import { RegisterAccountComponent } from './register-account/register-account.component';
import { LoginComponent } from './login/login.component';
import { SpinnerComponent } from './spinner/spinner.component';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {LoadingInterceptor} from "./interceptors/loading";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ApiMessageComponent } from './api-message/api-message.component';
import { ResetPasswordStartComponent } from './reset-password-start/reset-password-start.component';
import { ResetPasswordFinishComponent } from './reset-password-finish/reset-password-finish.component';
import { ShowInvitesComponent } from './show-invites/show-invites.component';
import { LogoutComponent } from './logout/logout.component';
import { QuizQuestionFormComponent } from './quiz-question-form/quiz-question-form.component';
import { TextQuestionFormComponent } from './text-question-form/text-question-form.component';
import { AnswerTextQuestionComponent } from './answer-text-question/answer-text-question.component';
import { AnswerQuizQuestionComponent } from './answer-quiz-question/answer-quiz-question.component';
import { QuestionsComponent } from './questions/questions.component';
import { QuizQuestionAnswersComponent } from './quiz-question-answers/quiz-question-answers.component';
import { TextQuestionAnswersComponent } from './text-question-answers/text-question-answers.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';



@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    HomeComponent,
    MenuLinkComponent,
    AboutComponent,
    AskQuestionComponent,
    NominateUserComponent,
    OnlineUsersComponent,
    AnswerQuestionComponent,
    RegisterAccountComponent,
    LoginComponent,
    SpinnerComponent,
    ApiMessageComponent,
    ResetPasswordStartComponent,
    ResetPasswordFinishComponent,
    ShowInvitesComponent,
    LogoutComponent,
    QuizQuestionFormComponent,
    TextQuestionFormComponent,
    AnswerTextQuestionComponent,
    AnswerQuizQuestionComponent,
    QuestionsComponent,
    QuizQuestionAnswersComponent,
    TextQuestionAnswersComponent,
    VerifyEmailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
     {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
