<!-- Footer For Desktop-->
    <div class="hidden md:block">
      <div class="mt-12 mb-12 flex justify-between">
        <div class="flex flex-col text-gray-600">
          <a href=" https://nominateedtech.blogspot.com/">Blogs</a>
          <a routerLink="/ask_question" >Ask question</a>
          <a (click)="guestLogin()" class="cursor-pointer">Guest Login</a>
        </div>
        <div>
          <h2 class="font-semibold text-center">Rayhan Miah</h2>
          <p class="text-gray-600">Copyright All rights reserved</p>
        </div>
        <div class="space-y-2">
          <p class="text-gray-600">You can find us at</p>
          <div class="flex gap-4">
            <a href="https://www.facebook.com/nominategame/">
              <img class="h-7 w-7" ngSrc="assets/img/footer/Facebook logo.png" height="41" width="41"
                   alt="Facebook Logo"/>
            </a>
            <a href="https://www.instagram.com/nominate.web.app/?hl=en">
              <img class="h-7 w-7" ngSrc="assets/img/footer/Instagram logo.png" height="40" width="40"
                   alt="Instagram Logo"/>
            </a>
            <a href="https://twitter.com/nominate_app">
              <img class="h-7 w-7" ngSrc="assets/img/footer/Twitter.png" height="40" width="40" alt="Twitter Logo"/>
            </a>
          </div>
        </div>
      </div>
    </div>

  <!-- Footer For mobile-->
    <div class="md:hidden">
      <div class="mt-12 mb-12 flex justify-between text-sm">
        <div class="flex flex-col text-gray-600">
          <a href="https://nominateedtech.blogspot.com/">Blogs</a>
          <a routerLink="/ask_question">Ask question</a>
          <a (click)="guestLogin()" class="cursor-pointer">Guest Login</a>
        </div>

        <div class="space-y-2">
          <p class="text-gray-600">You can find us at</p>
          <div class="flex gap-4">
            <a href="https://www.facebook.com/nominategame/">
              <img class="h-7 w-7" ngSrc="assets/img/footer/Facebook logo.png" height="41" width="41" alt="Facebook Logo"/>
            </a>
            <a href="https://www.instagram.com/nominate.web.app/?hl=en">
              <img class="h-7 w-7" ngSrc="assets/img/footer/Instagram logo.png" height="40" width="40" alt="Instagram Logo"/>
            </a>
            <a href="https://twitter.com/nominate_app">
              <img class="h-7 w-7" ngSrc="assets/img/footer/Twitter.png" height="40" width="40" alt="Twitter Logo"/>
            </a>
          </div>
        </div>
      </div>
      <div class="mb-8">
        <div class="text-center">
          <h2 class="font-semibold">Rayhan Miah</h2>
          <p class="text-gray-600">Copyright All rights reserved</p>
        </div>
      </div>
    </div>

