import { Component } from '@angular/core';
import {ApiService} from "../services/api.service";
import {ActivatedRoute} from "@angular/router";
import {QuizQuestionAnswerModel} from "../models/quiz-question-answer.model";

@Component({
  selector: 'app-quiz-question-answers',
  templateUrl: './quiz-question-answers.component.html',
  styleUrls: ['./quiz-question-answers.component.css']
})
export class QuizQuestionAnswersComponent {
  quizAnswers: QuizQuestionAnswerModel[] = [];

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {

      const questionId = params['questionId'];
      this.apiService.getQuizAnswers(questionId).then((data) => {
        this.quizAnswers = data;
      });

    });
  }

}
