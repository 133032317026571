<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop (used to position the menu correctly, for some reason)-->
<div class="md:justify-center hidden md:flex">
  <div style="width: 1440px;" class="bg-black">
  </div>
</div>

<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <form [formGroup]="loginForm">
  <div class="flex justify-center">
    <div>
      <div style="max-width: 741px;">
        <div class="flex space-x-2">
          <h2 class="text-xl text-primary font-semibold border-b border-gray-600 pb-3">Login</h2>
          <h2 class="text-xl text-primary font-semibold">account</h2>
        </div>
      </div>
      <div class="form-container px-5 py-24 mt-5 space-y-6 w-80 md:w-96">
        <div>
          <img ngSrc="assets/img/home/Nominate%20Logo.png" alt="Nominate Logo" class="mx-auto w-1/2" height="78"
               width="240"/>
        </div>

          <!-- Username Field -->
          <div>
            <div class="relative">
              <input
                type="text"
                placeholder="Username"
                class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50 text-center"
                formControlName="username"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg fill="#4A8DCB" class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"/>
                </svg>
              </div>
            </div>
            <div class="h-1 text-center">
              <div *ngIf="username?.errors?.['required'] && (username?.touched || username?.dirty)">
                <small class="text-red-500">Username is required</small>
              </div>
            </div>
          </div>
          <!-- End Username Field -->

        <!-- Password Field -->
          <div>
            <div class="relative">
              <input
                type="password"
                placeholder="Password"
                class="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg bg-gray-50 text-center"
                formControlName="password"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg fill="#4A8DCB" class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
                </svg>
              </div>
            </div>
            <div class="h-1 text-center">
              <div *ngIf="password?.errors?.['required'] && (password?.touched || password?.dirty)">
                <small class="text-red-500">Password is required</small>
              </div>
            </div>
          </div>
          <!-- End Password Field -->

         <button
           (click)="onSubmit()"
          class="inline-block bg-primary text-white p-2 rounded-md text-sm px-4 active:ring focus:ring hover:text-gray-200 active:text-gray-100 w-full">
           Login
        </button>

        <div class="space-y-1">
          <p style="font-size: .95rem;" class="text-center">Don't have an account yet? <a routerLink="/register" class="text-primary">Register</a></p>
        <div>
          <a style="font-size: .95rem;" class="text-center text-primary hover:cursor-pointer" (click)="guestLogin()" >Continue as a guest using website without logging in.</a>
        </div>
        <p style="font-size: .95rem;" class="text-center">Forgot password? <a routerLink="/reset_password_start" class="text-primary">Reset Here</a></p>
        </div>
      </div>
    </div>
  </div>
  </form>

  <!-- Footer -->
  <app-footer></app-footer>
</div>
