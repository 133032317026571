<div *ngIf="(apiMessageService.apiMessage | async)?.message">
  <div *ngIf="(apiMessageService.apiMessage | async)?.isSuccess"
       class="px-4 border border-green-500 text-green-700 mt-5 py-2 rounded bg-green-100 flex justify-between">
    <div>
      <i class="fa-solid fa-circle-info"></i>
      {{ (apiMessageService.apiMessage | async)?.message }}
    </div>
    <div class="px-2 rounded-full bg-green-200 hover:bg-green-300" (click)="close()">
       <i class="fa-sharp fa-solid fa-xmark"></i>
    </div>
  </div>

  <div *ngIf="!(apiMessageService.apiMessage | async)?.isSuccess"
       class="px-4 border border-red-500 text-red-700 mt-5 py-2 rounded bg-red-100 flex justify-between">
    <div>
      <i class="fa-solid fa-circle-info"></i>
      {{ (apiMessageService.apiMessage | async)?.message }}
    </div>
    <div class="px-2 rounded-full bg-red-200 hover:bg-red-300" (click)="close()">
      <i class="fa-sharp fa-solid fa-xmark"></i>
    </div>
  </div>
</div>
