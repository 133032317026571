<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop (used to position the menu correctly, for some reason)-->
<div class="md:justify-center hidden md:flex">
  <div style="width: 1440px;" class="bg-black">
  </div>
</div>


<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <!-- Why Nominate-->
 <div class="mx-auto" style="width: 620px;">
    <div class="flex space-x-2">
      <h2 class="text-xl text-primary font-semibold border-b border-gray-600 pb-1">Nominate</h2>
      <h2 class="text-xl text-primary font-semibold">a user</h2>
    </div>
  </div>


  <div class="flex justify-center">
    <div class="image-stacks-container  w-full md:w-3/5 mt-6">
      <div class="flex justify-center">
        <div class="my-6">
          <div class="image-stack" (click)="nominateUser()">
            <img ngSrc="assets/img/nominate_user/Nominate%20user-Flag-green.png" alt="Image 1" height="496" width="687">
            <img class="nominate-user h-20 w-20" ngSrc="assets/img/nominate_user/Nominate%20user.png" alt="Image 1"
                 height="134"
                 width="136">
            <p class="inner-text-title px-4">Nominate a user to answer the question</p>
            <p class="inner-text-content">You will be given a list of users that are currently online choose only one to
              answer the question</p>
          </div>

          <div class="image-stack" (click)="nominateRandomUser()">
            <img ngSrc="assets/img/nominate_user/Nominate%20user-Flag%20purple.png" alt="Image 1" height="497"
                 width="687">
            <img class="nominate-random h-20 w-20" ngSrc="assets/img/nominate_user/Nominate%20random.png" alt="Image 1"
                 height="147"
                 width="147">
            <p class="inner-text-title inner-text-title-random px-2">Nominate a random user to answer the question</p>
            <p class="inner-text-content">A random user selected from an online list will be asked to answer the
              question</p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- Footer -->
  <app-footer></app-footer>
</div>
