import {Injectable} from '@angular/core';
import {WsService} from "./ws.service";

@Injectable({
  providedIn: 'root'
})
export class PingServiceService {

  timeoutId: any;
  ACCESS_TOKEN_KEY = 'access'

  constructor(
    private wsService: WsService
  ) {
  }

  private ping () {
    const message = {
      type: 'ping',
      token: this.accessToken
    }
    this.wsService.sendMessage(JSON.stringify(message));
  }

  startPings() {
    if (!this.accessToken) {
      return;
    }

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setInterval(async () => {
      await this.ping();
    }, 1000);
  }

  stopPings() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  get accessToken(): string {
    let token = localStorage.getItem(this.ACCESS_TOKEN_KEY) || '';
    return token.trim();
  }
}

