import { Component } from '@angular/core';
import {ApiMessageService} from "../services/api-message/api-message.service";

@Component({
  selector: 'app-api-message',
  templateUrl: './api-message.component.html',
  styleUrls: ['./api-message.component.css']
})
export class ApiMessageComponent {
  constructor(public apiMessageService: ApiMessageService) {
  }

  close() {
    this.apiMessageService.clearApiMessage();
  }
}
