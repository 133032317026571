import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Invite} from "../show-invites/models";
import {FormBuilder} from "@angular/forms";
import {ApiService} from "../services/api.service";
import {ApiMessageService} from "../services/api-message/api-message.service";
import {LoginService} from "../services/login.service";
import {WsService} from "../services/ws.service";

@Component({
  selector: 'app-answer-text-question',
  templateUrl: './answer-text-question.component.html',
  styleUrls: ['./answer-text-question.component.css']
})
export class AnswerTextQuestionComponent {
  private inviteId: number = 0;
  private questionType: string = "";
  invite: Invite | null = null;
  invitesTimer : any = null;

    constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private fb: FormBuilder,
      private apiService: ApiService,
      private apiMessageService: ApiMessageService,
      private loginService: LoginService,
      private wsService: WsService
    ) { }

    answerForm = this.fb.group({
      answer_0: [''],
      answer_1: [''],
      answer_2: [''],
    });


    get showAnswer_1() { return this.invite && (this.invite?.number_of_answers || 0) > 1; }
    get showAnswer_2() { return this.invite && (this.invite?.number_of_answers || 0) > 2 }

    get showAnswer1Number() { return this.invite && (this.invite?.number_of_answers || 0) > 1 }

    get answer_0() { return this.answerForm.get('answer_0'); }
    get answer_1() { return this.answerForm.get('answer_1'); }
    get answer_2() { return this.answerForm.get('answer_2'); }

    requestInvite() {
      const message = {
        type: 'invite',
        token: this.loginService.accessToken,
        args: {
          invite_id: this.inviteId,
          question_type: 'text'
        }
      }
      this.wsService.sendMessage(JSON.stringify(message));
    }

    async ngOnInit() {
      this.inviteId = parseInt(this.activatedRoute.snapshot.params['inviteId']);
      this.questionType = 'text';

      const opened = await this.apiService.openInvite(this.questionType, this.inviteId);
      if (!opened) {
        await this.router.navigate(['/show_invites']);
      }

      this.invite = await this.apiService.getInvite(this.questionType, this.inviteId);
      if (!this.invite) {
        await this.router.navigate(['/show_invites']);
      }

      this.invitesTimer = setInterval(() => this.requestInvite(), 1000);

      this.wsService.invite.subscribe((wsResponse: any) => {
        const invite: Invite = wsResponse.data;
        if (invite.question_type != 'text') {
          return;
        }

        this.invite = invite;

         if (this.invite?.remaining_question_time_seconds == 0) {
          this.apiMessageService.setApiMessage({
            message: "Question has timed out",
            isSuccess: false
          });
          this.router.navigate(['/show_invites']);
        }
      });
    }

    ngOnDestroy() {
      clearInterval(this.invitesTimer);
    }

    get remainingGameTime(): string {
      if (!this.invite) {
        return "0";
      }
      const minutes = Math.floor(this.invite.game_timeout);
      const seconds = Math.floor((this.invite.game_timeout - minutes) * 60);

      const minutesStr = minutes < 10 ? "0" + minutes : minutes;
      const secondsStr = seconds < 10 ? "0" + seconds : seconds;
      return minutesStr + ":" + secondsStr;
    }

    get answer0Required(): boolean {
      const answer0 = this.answer_0?.value || "";
      return !answer0;
    }

    get answer1Required(): boolean {
      const answer1 = this.answer_1?.value || "";
      return !answer1 && !!this.showAnswer_1;
    }

    get answer2Required(): boolean {
      const answer2 = this.answer_2?.value || "";
      return !answer2 && !!this.showAnswer_2;
    }

    get remainingAnswerTime(): number {
      if (!this.invite) {
        return 0;
      }
      return Math.floor(this.invite.remaining_question_time_seconds);
    }

  async onSubmit() {
    const answer0 = this.answer_0?.value || "";
    if (!answer0) {
      return;
    }

    const answer1 = this.answer_1?.value || "";
    if (this.showAnswer_1 && !answer1) {
      return;
    }

    const answer2 = this.answer_2?.value || "";
    if (this.showAnswer_2 && !answer2) {
      return;
    }
    const formData = {
      answer0,
      answer1,
      answer2,
    }

    await this.apiService.answerQuestion(this.invite, formData);
  }
}
