import { Component } from '@angular/core';
import {ApiMessageService} from "../services/api-message/api-message.service";
import {Invite} from "./models";
import {ApiService} from "../services/api.service";
import {WsService} from "../services/ws.service";
import {LoginService} from "../services/login.service";

@Component({
  selector: 'app-show-invites',
  templateUrl: './show-invites.component.html',
  styleUrls: ['./show-invites.component.css']
})
export class ShowInvitesComponent {
  invites: Invite[] = [];

  invitesTimeout: any = null;

  constructor(
    private apiMessageService: ApiMessageService,
    private apiService: ApiService,
    private wsService: WsService,
    private loginService: LoginService
  ) {}


  async ngOnInit() {
    this.requestInvites();

    this.wsService.invites.subscribe((wsResponse: any) => {
      this.invites = wsResponse.data.invites;
    });

    this.invitesTimeout = setInterval(async () => {
      this.requestInvites();
    }, 2000);
  }

  private requestInvites() {
    const message = {
      type: 'invites',
      token: this.loginService.accessToken,
    }
    this.wsService.sendMessage(JSON.stringify(message));
  }

  async ngOnDestroy() {
    clearInterval(this.invitesTimeout);
  }
}
