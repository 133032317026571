<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop (used to position the menu correctly, for some reason)-->
<div class="md:justify-center hidden md:flex">
  <div style="width: 1440px;" class="bg-black">
  </div>
</div>


<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <!-- Why Nominate-->
  <div style="max-width: 560px" class="mx-auto mb-3">
    <div class="flex space-x-2">
      <h2 class="text-xl text-primary font-semibold border-b border-gray-600 pb-1">Users</h2>
      <h2 class="text-xl text-primary font-semibold">online</h2>
    </div>
  </div>

  <div class="online-users-container mx-auto">
    <h2 class="bg-primary text-white p-3 rounded-t-xl">Online Users</h2>
    <div class="flex justify-center">
      <div class="p-10 space-y-4">

        <div *ngFor="let user of onlineUsers" class="flex justify-between align-middle online-user-container px-4 py-2">
          <div>
            <input type="radio" [(ngModel)]="selectedName" [value]="user.username"/>
          </div>
          <div><span>{{user.username_alias}}</span></div>
          <div>
            <div class="w-4 h-4 mt-1 bg-green-500 rounded-full"></div>
          </div>
        </div>

        <div *ngIf="onlineUsers.length == 0">
          No users currently found online
        </div>
      </div>
    </div>
  </div>
  <div style="max-width: 560px" class="mx-auto">
  <div class="mt-4">
    <div class="flex justify-between pb-4">
      <button
        (click)="onBack()"
        class="inline-block bg-primary text-white p-2 rounded-md text-sm px-4 active:ring focus:ring hover:text-gray-200 active:text-gray-100">
        <a routerLink="/nominate_user">Back</a>
      </button>

      <button
        (click)="onSubmit()"
        class="inline-block bg-primary text-white p-2 rounded-md text-sm px-4 active:ring focus:ring hover:text-gray-200 active:text-gray-100">
        Next
      </button>
    </div>
  </div>
  </div>

  <!-- Footer -->
  <app-footer></app-footer>
</div>
