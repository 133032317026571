<!-- Menu -->
<app-menu></app-menu>
<!-- Hero Image Desktop -->
<div class="hidden md:block">
  <div style="position: relative;text-align: center;">
    <img style="width: 100%;height: 517px;" ngSrc="assets/img/home/Hero Image.png" height="517" alt="Hero Image"
         width="1440"/>
    <div style="position: absolute;top: 50%;
          left: 50%;transform: translate(-50%, -50%);
          font-size: 38px;line-height: 44px;text-align: center;
          word-spacing: 0px;
          color: black;
          " class="text-with-border font-bold">
      Transform learning with Nominate's innovative quiz game!
      Get Buzzed with Knowledge
    </div>
  </div>
</div>

<!-- Hero Mobile Desktop -->
<div class="md:hidden" style="position: relative;text-align: center;">
  <img ngSrc="assets/img/home/Hero Image.png" alt="Hero Image" height="517" width="1440"/>
  <div style="position: absolute;top: 50%;
            left: 50%;transform: translate(-50%, -50%);
            font-size: 17px;line-height: 30px;text-align: center;
            word-spacing: 0px;
            color: black;
               " class="text-with-border font-bold">
    Transform learning with Nominate's innovative quiz game!
    Get Buzzed with Knowledge
  </div>
</div>


<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <!-- Why Nominate-->
  <div class="flex space-x-2">
    <h2 class="text-2xl text-primary font-semibold border-b border-gray-600 pb-3">Why</h2>
    <h2 class="text-2xl text-primary font-semibold">Nominate</h2>
  </div>
  <h3 class="text-xl text-primary mt-4">Where Nominate is used</h3>

  <!-- Where Nominate is used Desktop-->
  <div class="hidden md:block">
    <div class="mt-4 flex justify-between">
      <div class="w-56 lg:w-72 text-center gap-2 border p-4 rounded" style="border-color: #70BAFE">
        <div class="flex justify-center">
          <div class="pr-7 pl-5 pt-4 pb-6 rounded-full" style="background-color: #70BAFE;">
            <img src="assets/img/home/Education icon.png" alt="Education Icon"/>
          </div>
        </div>
        <h3 class="text-2xl border-b-2 border-gray-900 inline-block pb-1 items-center">Education</h3>
        <p class="text-gray-600">Our self create quiz feature will challenge and stimulate your
          students'
          mind,
          making sure they are prepared and eager for class.
        </p>
      </div>

      <div class="w-56 lg:w-72 text-center space-y-2 border p-4 rounded" style="border-color: #87FFBA">
        <div class="flex justify-center">
          <div class="px-3 pt-4 pb-7 rounded-full" style="background-color:#87FFBA">
            <img src="assets/img/home/Work Place Icon.png" alt="Education Icon"/>
          </div>
        </div>
        <h3 class="text-2xl border-b-2 border-gray-900 inline-block pb-1 items-center">Work place</h3>
        <p class="text-gray-600">
          Energise your workforce before or during work to guarantee their readiness
          and maximum productivity throughout the workday.
        </p>
      </div>

      <div class="w-56 lg:w-72 text-center space-y-2 border p-4 rounded" style="border-color: #FFC3F6">
        <div class="flex justify-center">
          <div class="px-4 pt-4 pb-6 rounded-full" style="background-color: #FFC3F6;">
            <img src="assets/img/home/Social Icon.png" alt="Education Icon"/>
          </div>
        </div>
        <h3 class="text-2xl border-b-2 border-gray-900 inline-block pb-1 items-center">Social gatherings</h3>
        <p class="text-gray-600">
          Innovate interactions and break the ice at social gatherings by playing the game Nominate
        </p>
      </div>
    </div>
  </div>

  <!-- Where Nominate is used Mobile-->
  <div class="md:hidden mt-4">
    <div class="flex flex-col items-center gap-4">
      <div class="w-72 text-center gap-2 border p-4 rounded" style="border-color: #70BAFE">
        <div class="flex justify-center">
          <div class="pr-7 pl-5 pt-4 pb-6 rounded-full" style="background-color: #70BAFE;">
            <img src="assets/img/home/Education icon.png" alt="Education Icon"/>
          </div>
        </div>
        <h3 class="text-2xl border-b-2 border-gray-900 inline-block pb-1 items-center">Education</h3>
        <p class="text-gray-600">Our self create quiz feature will challenge and stimulate your
          students'
          mind,
          making sure they are prepared and eager for class.
        </p>
      </div>

      <div class="w-72 text-center space-y-2 border p-4 rounded" style="border-color: #87FFBA">
        <div class="flex justify-center">
          <div class="px-3 pt-4 pb-7 rounded-full" style="background-color:#87FFBA">
            <img src="assets/img/home/Work Place Icon.png" alt="Education Icon"/>
          </div>
        </div>
        <h3 class="text-2xl border-b-2 border-gray-900 inline-block pb-1 items-center">Work place</h3>
        <p class="text-gray-600">
          Energise your workforce before or during work to guarantee their readiness
          and maximum productivity throughout the workday.
        </p>
      </div>

      <div class="w-72 text-center space-y-2 border p-4 rounded" style="border-color: #FFC3F6">
        <div class="flex justify-center">
          <div class="px-4 pt-4 pb-6 rounded-full" style="background-color: #FFC3F6;">
            <img src="assets/img/home/Social Icon.png" alt="Education Icon"/>
          </div>
        </div>
        <h3 class="text-2xl border-b-2 border-gray-900 inline-block pb-1 items-center">Social gatherings</h3>
        <p class="text-gray-600">
          Innovate interactions and break the ice at social gatherings by playing the game Nominate
        </p>
      </div>

    </div>
  </div>

  <!-- Short Video About Nominate - Desktop -->
  <div class="hidden lg:block">
    <div>
      <div class="flex space-x-2 mt-4">
        <h2 class="text-2xl text-primary font-semibold border-b border-gray-600 pb-3">Short</h2>
        <h2 class="text-2xl text-primary font-semibold">Video about Nominate</h2>
      </div>
    </div>
    <div class="mt-6">
      <iframe src="https://player.vimeo.com/video/516736200?h=492a34701f" width="960" height="540"
              frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>

  <!-- Short Video About Nominate - Tablet -->
  <div class="hidden md:block lg:hidden">
    <div>
      <div class="flex space-x-2 mt-4">
        <h2 class="text-2xl text-primary font-semibold border-b border-gray-600 pb-3">Short</h2>
        <h2 class="text-2xl text-primary font-semibold">Video about Nominate</h2>
      </div>
    </div>
    <div class="mt-6">
      <iframe src="https://player.vimeo.com/video/516736200?h=492a34701f" width="538" height="303"
              frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>


  <!-- Short Video About Nominate - Mobile -->
  <div class="md:hidden">
    <div>
      <div class="flex space-x-2 mt-4">
        <h2 class="text-2xl text-primary font-semibold border-b border-gray-600 pb-3">Short</h2>
        <h2 class="text-2xl text-primary font-semibold">Video about Nominate</h2>
      </div>
    </div>
    <div class="mt-6">
      <iframe src="https://player.vimeo.com/video/516736200?h=492a34701f" width="384" height="216"
              frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>

  <!-- Footer -->
  <app-footer></app-footer>
</div>
