import { Component } from '@angular/core';
import {OnlineUser} from "../models/online-user.model";
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-online-users',
  templateUrl: './online-users.component.html',
  styleUrls: ['./online-users.component.css']
})
export class OnlineUsersComponent {

    onlineUsers: OnlineUser[] = [];
    questionType: string = '';
    questionId: number = 0;

    loadUsersTimer: any = null;

    selectedName: string = '';

    constructor(
      private activatedRoute: ActivatedRoute,
      private apiService: ApiService
    ) { }

    async ngOnInit() {
      this.activatedRoute.params.subscribe( async params => {
        this.questionType = params['questionType'];
        this.questionId = params['questionId'];
      });

      this.loadUsers();

      this.loadUsersTimer = setInterval( () => {
        this.loadUsers(false);
      }, 2000);
    }

    loadUsers (showLoading: boolean = true) {
      if(this.questionType == '' || this.questionId == 0) {
        return;
      }

      this.apiService.getOnlineUsers(this.questionType, this.questionId, showLoading)
        .then( (users) => {
          this.onlineUsers = users;
          let found = false;
          for(let user of this.onlineUsers) {
            if(user.username == this.selectedName) {
              found = true;
              break;
            }
          }
          if(!found) {
            this.selectedName = '';
          }
        });
    }

    ngOnDestroy() {
      clearInterval(this.loadUsersTimer);
    }

    onBack() {
      window.history.back();
    }

  async onSubmit() {
      if (this.selectedName == '') {
        return;
      }

      await this.apiService.nominateUser(
        this.selectedName,
        this.questionType,
        this.questionId
      )
  }
}
