<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop (used to position the menu correctly, for some reason)-->
<div class="md:justify-center hidden md:flex">
  <div style="width: 1440px;" class="bg-black">
  </div>
</div>


<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <!-- Why Nominate-->
 <div class="mx-auto" style="max-width: 620px;">
    <div class="flex space-x-2">
      <h2 class="text-xl text-primary font-semibold border-b border-gray-600 pb-1">Please</h2>
      <h2 class="text-xl text-primary font-semibold">answer the question below</h2>
    </div>
  </div>


  <div class="flex justify-center" >
    <div class="image-stacks-container w-full md:w-3/5 mt-6 border-2 border-gray-300" style="background-color: rgba(74, 141, 203, 0.25);">
      <div class="flex justify-center">
        <div class="my-6">
          <div class="image-stack">
            <div class="flex justify-center">
              <img ngSrc="assets/img/answer_question/Question%20mark.png" alt="Image 1" height="591" width="550" class="h-80 w-80">
            </div>
            <p class="inner-text-content text-primary font-bold text-center px-10 w-full">
              {{invite?.question_text}}
            </p>
          </div>
          <p class="text-primary mb-2 font-semibold ml-4 md:ml-0">Please select your answer below</p>
          <div class="space-y-4 mx-4 md:mx-0">
            <div (click)="onSubmit(originallyOrderedAnswers[0])" class="px-1 text-white" style="background: #116CF5;border: 3px solid #FFFFFF;box-shadow: 3px 3px 0 0 black;cursor: pointer">A. {{originallyOrderedAnswers[0]}}</div>
            <div (click)="onSubmit(originallyOrderedAnswers[1])" class="px-1 text-white" style="background: #FF8A00;border: 3px solid #FFFFFF;box-shadow: 3px 3px 0 0 black;cursor: pointer">B. {{originallyOrderedAnswers[1]}}</div>
            <div (click)="onSubmit(originallyOrderedAnswers[2])" class="px-1 text-white" style="background: #BB6BD9;border: 3px solid #FFFFFF;box-shadow: 3px 3px 0 0 black;cursor: pointer">C. {{originallyOrderedAnswers[2]}}</div>
          </div>
        </div>
      </div>
       <div class="flex justify-between px-4 md:px-20 py-5">
        <div class="space-y-2">
          <p class="font-semibold text-primary">Game Timer</p>
          <div class="image-stack-2">
            <img ngSrc="assets/img/answer_question/Game%20clock.png" alt="Image 1" height="91" width="76">
            <p class="inner-game-clock-content text-gray-600 font-bold text-center px-2">
              {{remainingGameTime}}
            </p>
          </div>
        </div>
        <div class="space-y-2">
          <p class="font-semibold text-primary">Quiz Timer</p>
          <div class="image-stack-2">
            <img ngSrc="assets/img/answer_question/Question%20timer.png" alt="Image 1" height="87" width="81">
            <p class="inner-quiz-clock-content text-gray-600 font-bold text-center px-2">
              {{remainingAnswerTime}}s
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- Footer -->
  <app-footer></app-footer>
</div>
