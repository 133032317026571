<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop -->
<div class="hidden md:block">
  <img style="width: 100%;height: 517px;" ngSrc="assets/img/ask_question/HeroImage.png" height="508" alt="Hero Image"
       width="1439"/>
</div>

<!-- Hero Mobile Desktop -->
<div class="md:hidden" style="position: relative;text-align: center;">
  <img style="width: 100%;" ngSrc="assets/img/ask_question/HeroImage.png" height="508" alt="Hero Image"
       width="1439"/>
</div>


<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <!-- Why Nominate-->
  <div class="flex space-x-2">
    <h2 class="text-xl text-primary font-semibold border-b border-gray-600 pb-3">Please</h2>
    <h2 class="text-xl text-primary font-semibold">select from an option below</h2>
  </div>

  <div class="flex justify-center">
    <div class="image-stacks-container  w-full md:w-3/5 mt-6">
      <div class="flex justify-center">
        <div class="my-6">
          <div class="image-stack" routerLink="/text_question_form">
            <div class="flex justify-center">
              <img ngSrc="assets/img/ask_question/Vectorblob%201.png" alt="Image 1" height="356" width="356" class="h-64 w-64">
            </div>
            <img class="info-vector" ngSrc="assets/img/ask_question/InfoVector.png" alt="Image 1" height="38"
                 width="19">
            <img class="inner-2 h-20 w-24" ngSrc="assets/img/ask_question/Text.png" alt="Image 2" height="117" width="132">
            <p class="inner-text text-2xl text-white font-bold">Text</p>
          </div>

          <div class="image-stack" routerLink="/quiz_question_form">
            <div class="flex justify-center">
              <img ngSrc="assets/img/ask_question/Vectorblob%202.png" alt="Image 1" height="356" width="356" class="h-64 w-64">
            </div>
            <img class="info-vector" ngSrc="assets/img/ask_question/InfoVector.png" alt="Image 1" height="38"
                 width="19">
            <img class="inner h-20 w-16" ngSrc="assets/img/ask_question/Quiz.png" alt="Image 2" height="123" width="99">
            <p class="inner-text text-2xl text-white font-bold">Quiz</p>
          </div>
        </div>
      </div>

<!--      <div class="flex justify-between px-20 pb-4">-->
<!--        <button-->
<!--          class="inline-block bg-primary text-white p-2 rounded-md text-sm px-4 active:ring focus:ring hover:text-gray-200 active:text-gray-100">-->
<!--          <a href="#">Back</a>-->
<!--        </button>-->

<!--        <button-->
<!--          class="inline-block bg-primary text-white p-2 rounded-md text-sm px-4 active:ring focus:ring hover:text-gray-200 active:text-gray-100">-->
<!--          <a routerLink="/ask_question_form">Next</a>-->
<!--        </button>-->
<!--      </div>-->
    </div>
  </div>


  <!-- Footer -->
  <app-footer></app-footer>
</div>
