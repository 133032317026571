<!-- Desktop Menu -->
<nav class="bg-white py-4 hidden lg:block md:w-full md:px-4 lg:w-4/5 md:mx-auto">
  <div class="flex items-center">
    <div>
      <a href="#">
        <img class="h-12 w-44" ngSrc="assets/img/home/Nominate Logo.png" alt="Logo" height="78" width="240"/>
      </a>
    </div>
    <div class="flex items-center ml-10 justify-between w-full">
      <div class="space-x-5">
        <app-menu-link title="Home" routerLinkValue="/home"></app-menu-link>
        <app-menu-link title="About Nominate" routerLinkValue="/about"></app-menu-link>
        <app-menu-link *ngIf="isLoggedIn" title="Show Invites" routerLinkValue="/show_invites"></app-menu-link>
        <app-menu-link *ngIf="isLoggedIn" title="Start a quiz/text" routerLinkValue="/ask_question"></app-menu-link>
        <app-menu-link *ngIf="isLoggedIn" title="Answers" routerLinkValue="/answers"></app-menu-link>
      </div>

      <div class="space-x-5">
        <a (click)="guestLogin()"
           *ngIf="!isLoggedIn"
          class="pb-1 inline-block border-b-4 hover:border-gray-400 border-white hover:cursor-pointer">Guest Login</a>
        <a *ngIf="isLoggedIn"
          class="pb-1 inline-block border-b-4 hover:border-gray-400 border-white hover:cursor-pointer">{{this.meModel?.username || '    '}}</a>
        <app-menu-link *ngIf="!isLoggedIn" title="Login" routerLinkValue="/login"></app-menu-link>
        <app-menu-link *ngIf="isLoggedIn" title="Logout" routerLinkValue="/logout"></app-menu-link>
        <a *ngIf="!isLoggedIn" class="inline-block bg-primary text-white p-2 rounded-md text-sm px-4 active:ring focus:ring hover:text-gray-200 active:text-gray-100" routerLink="/register">Register</a>
      </div>
    </div>
  </div>
  <app-api-message></app-api-message>
</nav>

<!-- Mobile Menu-->
<nav class="w-full px-4 lg:hidden py-4">
  <div class="flex justify-between items-center">
    <div>
      <a href="#">
        <img class="h-12 w-36" ngSrc="assets/img/home/Nominate Logo.png" height="78" width="240" alt="menu"/>
      </a>
    </div>
    <div>
      <div>
        <button (click)="toggleMobileMenu()"
                type="button"
                class="bg-gray-200 rounded-md p-2 inline-flex items-center justify-center text-gray-900 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                aria-expanded="false">
          <span class="sr-only">Open menu</span>
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="mt-4" *ngIf="isMobileMenuOpen">
    <div class="flex flex-col space-y-2">
      <app-menu-link title="Home" routerLinkValue="/home"></app-menu-link>
      <app-menu-link title="About Nominate" routerLinkValue="/about"></app-menu-link>
      <app-menu-link *ngIf="isLoggedIn" title="Show Invites" routerLinkValue="/show_invites"></app-menu-link>
      <app-menu-link *ngIf="isLoggedIn" title="Start a quiz/text" routerLinkValue="/ask_question"></app-menu-link>
      <app-menu-link *ngIf="isLoggedIn" title="Answers" routerLinkValue="/answers"></app-menu-link>
      <a (click)="guestLogin()"
         *ngIf="!isLoggedIn"
         class="pb-1 inline-block border-b-4 hover:border-gray-400 border-white hover:cursor-pointer">Guest Login</a>
      <app-menu-link *ngIf="!isLoggedIn" title="Login" routerLinkValue="/login"></app-menu-link>
      <a *ngIf="isLoggedIn"
         class="pb-1 inline-block border-b-4 hover:border-gray-400 border-white hover:cursor-pointer">{{this.meModel?.username || '    '}}</a>
      <app-menu-link *ngIf="isLoggedIn" title="Logout" routerLinkValue="/logout"></app-menu-link>
      <a *ngIf="!isLoggedIn" class="text-primary" routerLink="/register">Register</a>
    </div>
  </div>
  <app-api-message></app-api-message>
</nav>

