<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop (used to position the menu correctly, for some reason)-->
<div class="md:justify-center hidden md:flex">
  <div style="width: 1440px;" class="bg-black">
  </div>
</div>

<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <div class="flex justify-center">
    <div>
      <div style="max-width: 741px;">
        <div class="flex space-x-2">
          <h2 class="text-xl text-primary font-semibold border-b border-gray-600 pb-3">Show</h2>
          <h2 class="text-xl text-primary font-semibold">Invites</h2>
        </div>
      </div>

      <!-- Mobile Table -->
       <div class="form-container mt-5 space-y-6 md:hidden" style="width: 320px;">
        <table class="border-2 w-full border-gray-200">
          <tbody>
          <tr class="bg-primary text-white text-center">
            <th class="py-2 border-r-2 border-gray-300">Sender</th>
            <th class="py-2 border-r-2 border-gray-300">Remaining Time</th>
            <th class="py-2">Answer</th>
          </tr>
          <tr class="text-center" *ngIf="invites.length == 0">
            <td class="py-2 border-r-2 border-gray-300" colspan="5">You have no pending invites</td>
          </tr>
          <tr class="text-center" *ngFor="let invite of invites">
            <td class="py-2 border-r-2 border-gray-300">{{invite.sender}}</td>
            <td class="py-2 border-r-2 border-gray-300">{{invite.remaining_question_time_seconds}} seconds</td>
            <td class="py-2 ">
              <a *ngIf="invite.question_type == 'text'" routerLink="/answer_text_invite/{{invite.id}}"  class="text-primary hover:text-red-400">Answer</a>
              <a *ngIf="invite.question_type == 'quiz'" routerLink="/answer_quiz_invite/{{invite.id}}"  class="text-primary hover:text-red-400">Answer</a>
            </td>
          </tr>
          </tbody>
        </table>
       </div>
      <!-- End Mobile Table -->


      <!-- Desktop Table -->
      <div class="form-container mt-5 space-y-6 hidden md:block" style="width: 800px">
        <table class="border-2 w-full border-gray-200">
          <tbody>
          <tr class="bg-primary text-white text-center">
            <th class="py-2 border-r-2 border-gray-300">Sender</th>
            <th class="py-2 border-r-2 border-gray-300">Question Timeout</th>
            <th class="py-2 border-r-2 border-gray-300">Game Timeout</th>
            <th class="py-2 border-r-2 border-gray-300">Remaining Time</th>
            <th class="py-2">Answer</th>
          </tr>
          <tr class="text-center" *ngIf="invites.length == 0">
            <td class="py-2 border-r-2 border-gray-300" colspan="5">You have no pending invites</td>
          </tr>
          <tr class="text-center" *ngFor="let invite of invites">
            <td class="py-2 border-r-2 border-gray-300">{{invite.sender}}</td>
            <td class="py-2 border-r-2 border-gray-300">{{invite.question_timeout}} seconds</td>
            <td class="py-2 border-r-2 border-gray-300">{{invite.game_timeout}} minutes</td>
            <td class="py-2 border-r-2 border-gray-300">{{invite.remaining_question_time_seconds}} seconds</td>
            <td class="py-2 border-r-2 border-gray-300">
              <a *ngIf="invite.question_type == 'text'" routerLink="/answer_text_invite/{{invite.id}}"  class="text-primary hover:text-red-400">Answer</a>
              <a *ngIf="invite.question_type == 'quiz'" routerLink="/answer_quiz_invite/{{invite.id}}"  class="text-primary hover:text-red-400">Answer</a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <!-- End Desktop Table -->

    </div>
  </div>

  <!-- Footer -->
  <app-footer></app-footer>
</div>
