import { Injectable } from '@angular/core';
import {ValidationErrors, Validators} from "@angular/forms";
import {TextQuestionRequest} from "./models";
import {ApiService} from "../services/api.service";

@Injectable({
  providedIn: 'root'
})
export class TextQuestionFormService {

  constructor(
    private apiService: ApiService
  ) { }

   async validateField(fieldObject: any): Promise<ValidationErrors | null> {
    const defaultTextQuestion: TextQuestionRequest = {
        question_text: '',
        question_timer_seconds: 0,
        end_timer_minutes: 0,
        number_of_answers: 0
    };
    const textQuestion: TextQuestionRequest = {...defaultTextQuestion, ...fieldObject};
    const result = await this.apiService.askTextQuestionValidate(textQuestion);

    const fieldObjectKey = Object.keys(fieldObject)[0];
    if (result == null || result?.[fieldObjectKey] == null) {
      return null;
    }
    const validationResult: any = {};
    validationResult[fieldObjectKey] = result?.[fieldObjectKey];
    return validationResult;
  }
}
