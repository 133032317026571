<!-- Menu -->
<app-menu></app-menu>

<!-- Hero Image Desktop (used to position the menu correctly, for some reason)-->
<div class="md:justify-center hidden md:flex">
  <div style="width: 1440px;" class="bg-black">
  </div>
</div>

<!-- Rest of body container -->
<div class="w-full px-4 lg:w-3/4 md:mx-4 lg:mx-auto mt-4">
  <div class="flex justify-center">
    <div>
      <div style="max-width: 741px;">
        <div class="flex space-x-2">
          <h2 class="text-xl text-primary font-semibold border-b border-gray-600 pb-3">Answers</h2>
          <h2 class="text-xl text-primary font-semibold">- leaderboard</h2>
        </div>
      </div>

       <div class="form-container mt-5 space-y-6 md:hidden" style="width: 320px;">
        <table class="border-2 w-full border-gray-200">
          <tbody>
          <tr class="bg-primary text-white text-center">
            <th class="py-2 border-r-2 border-gray-300">Name</th>
            <th class="py-2 border-r-2 border-gray-300">Answer</th>
            <th class="py-2">Right/Wrong</th>
          </tr>
          <ng-container *ngFor="let answer of quizAnswers; let i = index;">
            <tr [class]="i % 2 == 0 ? 'text-center' : 'bg-gray-200 text-center'">
              <td class="py-2 border-r-2 border-gray-300">{{answer.answer_author}}</td>
              <td class="py-2 border-r-2 border-gray-300">{{answer.answer}}</td>
              <td class="py-2">
                <div *ngIf="answer.is_correct" class="flex justify-center">
                  <svg fill="#219653" class="h-7 w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
                </div>
                <div class="flex justify-center" *ngIf="!answer.is_correct">
                  <svg fill="#FF0000" class="h-7 w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>
                </div>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="quizAnswers.length == 0">
            <td colspan="3" class="py-4 text-center">No answers found</td>
          </tr>
          </tbody>
        </table>
       </div>

      <div class="form-container mt-5 space-y-6 hidden md:block" style="width: 740px;">
        <table class="border-2 w-full border-gray-200">
          <tbody>
          <tr class="bg-primary text-white text-center">
            <th class="py-2 border-r-2 border-gray-300">Name</th>
            <th class="py-2 border-r-2 border-gray-300">Answer</th>
            <th class="py-2">Right/Wrong</th>
          </tr>

          <ng-container *ngFor="let answer of quizAnswers; let i = index;">
            <tr [class]="i % 2 == 0 ? 'text-center' : 'bg-gray-200 text-center'">
              <td class="py-2 border-r-2 border-gray-300">{{answer.answer_author}}</td>
              <td class="py-2 border-r-2 border-gray-300">{{answer.answer}}</td>
              <td class="py-2">
                <div *ngIf="answer.is_correct" class="flex justify-center">
                  <svg fill="#219653" class="h-7 w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
                </div>
                <div class="flex justify-center" *ngIf="!answer.is_correct">
                  <svg fill="#FF0000" class="h-7 w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>
                </div>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="quizAnswers.length == 0">
            <td colspan="3" class="py-4 text-center">No answers found</td>
          </tr>
          </tbody>
        </table>
      </div>


    </div>
  </div>

  <!-- Footer -->
  <app-footer></app-footer>
</div>
