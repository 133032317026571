import { Component } from '@angular/core';
import {ApiMessageService} from "../services/api-message/api-message.service";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent {
  constructor(private apiMessageService: ApiMessageService){

  }

  ngOnInit() {
  }

}
