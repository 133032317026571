import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {AboutComponent} from "./about/about.component";
import {AskQuestionComponent} from "./ask-question/ask-question.component";
import {NominateUserComponent} from "./nominate-user/nominate-user.component";
import {OnlineUsersComponent} from "./online-users/online-users.component";
import {RegisterAccountComponent} from "./register-account/register-account.component";
import {LoginComponent} from "./login/login.component";
import {ResetPasswordStartComponent} from "./reset-password-start/reset-password-start.component";
import {ResetPasswordFinishComponent} from "./reset-password-finish/reset-password-finish.component";
import {authGuard} from "./services/auth.guard";
import {ShowInvitesComponent} from "./show-invites/show-invites.component";
import {LogoutComponent} from "./logout/logout.component";
import {QuizQuestionFormComponent} from "./quiz-question-form/quiz-question-form.component";
import {TextQuestionFormComponent} from "./text-question-form/text-question-form.component";
import {AnswerTextQuestionComponent} from "./answer-text-question/answer-text-question.component";
import {AnswerQuizQuestionComponent} from "./answer-quiz-question/answer-quiz-question.component";
import {QuestionsComponent} from "./questions/questions.component";
import {QuizQuestionAnswersComponent} from "./quiz-question-answers/quiz-question-answers.component";
import {TextQuestionAnswersComponent} from "./text-question-answers/text-question-answers.component";
import {VerifyEmailComponent} from "./verify-email/verify-email.component";

const canActivate = [authGuard];

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'ask_question', component: AskQuestionComponent, canActivate },
  { path: 'nominate_user/:questionType/:questionId', component: NominateUserComponent, canActivate },
  { path: 'online_users/:questionType/:questionId', component: OnlineUsersComponent, canActivate },
  { path: 'register', component: RegisterAccountComponent},
  { path: 'login', component: LoginComponent },
  { path: 'reset_password_start', component: ResetPasswordStartComponent },
  { path: 'reset_password_finish/:token', component: ResetPasswordFinishComponent },
  { path: 'show_invites', component: ShowInvitesComponent, canActivate},
  { path: 'logout', component: LogoutComponent},
  {path: 'quiz_question_form', component: QuizQuestionFormComponent, canActivate},
  {path: 'text_question_form', component: TextQuestionFormComponent, canActivate},
  {path: 'answer_text_invite/:inviteId', component: AnswerTextQuestionComponent, canActivate},
  {path: 'answer_quiz_invite/:inviteId', component: AnswerQuizQuestionComponent, canActivate},
  {path: 'answers', component: QuestionsComponent, canActivate},
  {path: 'quiz_question_answers/:questionId', component: QuizQuestionAnswersComponent, canActivate},
  {path: 'text_question_answers/:questionId', component: TextQuestionAnswersComponent, canActivate},
  {path: 'verify_email/:token', component: VerifyEmailComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
