import { Injectable } from '@angular/core';
import {ValidationErrors} from "@angular/forms";
import {
  API_RESET_PASSWORD_FINISH,
  API_RESET_PASSWORD_FINISH_VALIDATE
} from "../services/constants";
import {ApiMessage} from "../models/api_response";
import {LoaderService} from "../services/loader/loader.service";

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordFinishService {

  constructor(private loaderService: LoaderService) { }

   async validateNewPassword(password: string): Promise<ValidationErrors | null> {
    const result = await this.resetPasswordFinishValidate(
      password,
      '',
    );
    if (result == null || result?.['new_password'] == null) {
      return null;
    }
    return {new_password: result?.['new_password']};
  }

  async resetPasswordFinishValidate(newPassword: string, token: string): Promise<ValidationErrors | null> {
    try {
      const body = {'new_password': newPassword, token};
      const response = await fetch(API_RESET_PASSWORD_FINISH_VALIDATE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      if (response.ok) {
        return null;
      }
      return await response.json();
    } catch (e) {
      console.error(e);
      return {unknown: 'Unknown error'};
    }
  }

  async resetPasswordFinish(newPassword: string, token: string): Promise<ApiMessage> {
    try {
      this.loaderService.setLoading(true);
      const body = {'new_password': newPassword, token};
      const response = await fetch(API_RESET_PASSWORD_FINISH, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      this.loaderService.setLoading(false);

      if (response.ok) {
        return {
          isSuccess: true,
          message: "Reset was successful. Please login with your new password."
        }
      }

      return {
        isSuccess: false,
        message: "Reset failed. Please try again."
      }
    } catch (e) {
      this.loaderService.setLoading(false);
      return {
        isSuccess: false,
        message: "Reset failed. Please try again."
      }
    }
  }
}
